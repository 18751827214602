import dayjs from 'dayjs'

import { get } from '@/utils/axios'
import { helpers } from '@/utils/helpers'

const data = {
  expirationDate: '',
  isPopup: true,
  popupDate: null,
  isPopupMini: true,
  popupMiniDate: null
}

export const state = {
  data
}

export const getters = {
  getData: (state) => {
    return state.data
  },
  getPostTypeList: (state, getters, rootState) => {
    let postTypeList = []
    state?.data?.postTypeList.forEach((item) => {
      if (item.value != 'ARTICLE' || (item.value == 'ARTICLE' && rootState?.user?.data?.Influencer?.IsPjfOfficial == 1)) {
        postTypeList.push(item)
      }
    })

    return postTypeList
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  },
  RESET_DATA(state) {
    state.data = { ...data }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  resetData: (context) => {
    context.commit('RESET_DATA')
  },
  fetchData: async (context, payload) => {
    const expirationDate = context.state.data.expirationDate
    const currentDate = dayjs().valueOf()

    let isExpired = currentDate > expirationDate
    if (payload?.force) {
      isExpired = true
    }

    let getParams = {}

    if (payload?.params && Object.keys(payload.params).length > 0) {
      for (let paramsKey in payload.params) {
        if ((payload.params[paramsKey] === 1 && helpers.isBlank(context.state.data[paramsKey])) || expirationDate == '' || isExpired) {
          getParams[paramsKey] = payload.params[paramsKey]
        }
      }
    }

    // CHANGE LANGUAGE RE-FETCH DATA
    if (payload?.refetch) {
      for (let key in context.state.data) {
        if (!data.hasOwnProperty(key)) {
          getParams[key] = 1
        }
      }
    }

    if (getParams && Object.keys(getParams).length > 0) {
      if (context.rootGetters['translation/getData']?.languageCode) {
        getParams['LanguageCode'] = context.rootGetters['translation/getData']?.languageCode
      }

      let configData = {}

      const returnData = await get(`/public/config`, getParams)

      if (!helpers.isBlank(returnData) && !helpers.isEmpty(returnData)) {
        // prettier-ignore
        configData.expirationDate = dayjs().add(1, 'hour').valueOf()

        configData = {
          ...configData,
          ...returnData
        }
      }

      context.commit('UPDATE_DATA', configData)
    }
  }
}

export const config = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
