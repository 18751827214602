import { useStore } from '@/store'

const store = useStore()

const translation = {
  getLanguage() {
    let languageCode = store.getters['translation/getData']?.languageCode
    return languageCode
  },
  getTranslation(translationCode, def) {
    let translationName = store.getters['translation/getData']?.translations?.[translationCode]?.v || def || translationCode

    return translationName
  }
}

const install = (app) => {
  app.config.globalProperties.$t = translation
}

export { install as default, translation }
