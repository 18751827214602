import { checkMeta } from '@/routes/@common.js'
const routes = [
  {
    name: 'wechatRedirect',
    path: '/auth/wechat/:mode/:p_code?',
    meta: {
      navigation: {
        isShown: false,
        title: 'wechat',
        activeLink: 'wechatPage',
        tabbarLink: 'wechatPage'
      },
      isSecured: false,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "wechat" */ /* webpackMode: "lazy" */ '@/pages/wechat/WechatRedirectPage.vue')
  }
]

export default routes
