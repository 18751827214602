import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    name: 'postPage',
    path: '/post/',
    meta: {
      navigation: {
        isShown: true,
        title: 'Post',
        activeLink: 'postPage',
        tabbarLink: 'postPage'
      },
      isSecured: true,
      hasRoles: []
    },
    redirect: '/home/explore',
    routes: [
      {
        name: 'postCreatePage',
        path: 'create/',
        meta: {
          navigation: {
            isShown: false,
            title: 'Post Create',
            activeLink: 'postCreatePage',
            tabbarLink: 'profilePage'
          },
          isSecured: true,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "post-create" */ /* webpackMode: "lazy" */ '@/pages/post/SavePostPage.vue')
      },
      {
        name: 'postUpdatePage',
        path: 'update/:key',
        meta: {
          navigation: {
            isShown: false,
            title: 'Post Update',
            activeLink: 'postUpdatePage',
            tabbarLink: 'profilePage'
          },
          isSecured: true,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "post-update" */ /* webpackMode: "lazy" */ '@/pages/post/SavePostPage.vue')
      }
    ]
  }
]

export default routes
