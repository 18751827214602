import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/zh-cn'

import { get, post } from '@/utils/axios'
import { helpers } from '@/utils/helpers'

const data = {
  languageCode: 'CHS',
  translations: {}
}

export const state = {
  data
}

export const getters = {
  getData: (state) => {
    return state.data
  },
  getLanguage: (state) => {
    return state.data.languageCode
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  },
  RESET_DATA(state) {
    state.data = { ...data }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  setLanguage: async (context, payload) => {
    context.commit('UPDATE_DATA', payload)

    if (payload.languageCode === 'CHS' || payload.languageCode === 'CHT') {
      dayjs.locale('zh-cn')
    } else {
      dayjs.locale('en')
    }

    if (payload?.postUpdate == true) {
      await post(`/user/update/language`, { LanguageCode: payload.languageCode })
    }
  },
  resetData: (context) => {
    context.commit('RESET_DATA')
  },
  fetchData: async (context, payload) => {
    let isCheckStore = payload?.isCheckStore || true
    let isRefetch = payload?.force ? true : false

    let languageCode = context?.state?.data?.languageCode
    let currentTranslations = context?.state?.data?.translations

    let fetchTranslationCode = []

    if (payload?.translationCode) {
      for (let item of payload?.translationCode) {
        if (isRefetch || !isCheckStore || helpers.isBlank(currentTranslations[item])) {
          fetchTranslationCode.push(item)
        } else {
          const expirationDate = currentTranslations[item].e
          const currentDate = dayjs().valueOf()
          let isExpired = currentDate > expirationDate

          if (isExpired) {
            fetchTranslationCode.push(item)
          }
        }
      }
    }

    // CHANGE LANGUAGE RE-FETCH DATA
    if (payload?.refetch) {
      for (let translationCode in currentTranslations) {
        if (fetchTranslationCode.indexOf(translationCode) <= -1) {
          fetchTranslationCode.push(translationCode)
        }
      }
    }

    let translationData = {}
    let translationParams = { LanguageCode: languageCode }

    if (!helpers.isEmpty(fetchTranslationCode)) {
      translationParams.TranslationCode = fetchTranslationCode
    } else {
      translationParams.TranslationType = 'APP'
    }

    const returnData = await post(`/public/translation/list`, translationParams)

    if (!helpers.isBlank(returnData) && !helpers.isEmpty(returnData)) {
      translationData.translations = {
        ...currentTranslations
      }

      // prettier-ignore
      let expirationDate = dayjs().add(1, 'hour').valueOf()
      for (let translationCode in returnData) {
        translationData.translations[translationCode] = {
          e: expirationDate,
          v: returnData[translationCode]
        }
      }
    }

    context.commit('UPDATE_DATA', translationData)
  }
}

export const translation = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
