import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    name: 'addressSettings',
    path: '/address/settings/',
    meta: {
      navigation: {
        isShown: true,
        title: 'Address Settings',
        activeLink: 'addressSettings',
        tabbarLink: 'addressSettings'
      },
      isSecured: true,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "address-setting" */ /* webpackMode: "lazy" */ '@/pages/address/AddressSettings.vue')
  }
]

export default routes
