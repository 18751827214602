import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    name: 'discountsPage',
    path: '/discounts/',
    meta: {
      navigation: {
        isShown: true,
        title: 'discounts',
        activeLink: 'discountsPage',
        tabbarLink: 'discountsPage'
      },
      isSecured: false,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "discounts" */ /* webpackMode: "lazy" */ '@/pages/discounts/DiscountsPage.vue')
  }
]

export default routes
