<template>
  <f7-toolbar class="main-toolbar" tabbar labels position="bottom">
    <!-- <div v-if="$h.isMiniProgram()" class="footer-icp">
      <p>备案号: (<a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021057820号-3X</a>)</p>
    </div> -->

    <f7-link :class="{ active: tabbarLink == 'homePage' }" href="/home/">
      <font-awesome-icon v-if="tabbarLink == 'homePage'" :icon="['fas', 'home-lg']" fixed-width />
      <font-awesome-icon v-else :icon="['fas', 'home-lg']" fixed-width />
      <h4>{{ $t.getTranslation('LBL_CONTENTS') }}</h4>
    </f7-link>
    <f7-link :class="{ active: tabbarLink == 'shopPage' }" href="/shop/">
      <font-awesome-icon v-if="tabbarLink == 'shopPage'" :icon="['fas', 'store-alt']" fixed-width />
      <font-awesome-icon v-else :icon="['fas', 'store-alt']" fixed-width />
      <h4>
        {{ $configs?.hideProductPrice ? $t.getTranslation('LBL_WINES') : $t.getTranslation('LBL_SHOP') }}
      </h4>
    </f7-link>

    <!-- <f7-link class="ripple" href="/post/create/?mode=tastingrecord">
      <font-awesome-icon :icon="['fal', 'barcode-read']" fixed-width />
      <h4>{{ $t.getTranslation('LBL_SCAN') }}</h4>
    </f7-link> -->

    <!-- <f7-link class="ripple" href="/home/weekly/">
      <font-awesome-icon :icon="['fas', 'calendar-week']" fixed-width />
      <h4>{{ $t.getTranslation("LBL_WEEKLY_PICKS") }}</h4>
    </f7-link> -->

    <f7-link :class="{ active: tabbarLink == 'promotionPage' }" href="/shop">
      <font-awesome-icon :icon="['fas', 'calendar']" fixed-width />
      <h4>{{ $t.getTranslation('LBL_NEW') }}</h4>
    </f7-link>

    <!-- <f7-link :class="{ active: tabbarLink == 'weekylyrecommendationPage' }" href="/recommendation/">
      <font-awesome-icon v-if="tabbarLink == 'weekylyrecommendationPage'" :icon="['fas', 'calendar']" fixed-width />
      <font-awesome-icon v-else :icon="['fas', 'calendar']" fixed-width />
      <h4>
        {{ $t.getTranslation('LBL_WEEKLY') }}
        <span v-if="!isLoading && IsViewWeeklyProduct && tabbarLink != 'weekylyrecommendationPage'" class="weekly-bg"
          ><p class="weekly-point">{{ $t.getTranslation('LBL_NEW') }}</p></span
        >
        <span v-if="!userWeeklyStatus && weeklyProductStatus && tabbarLink != 'weekylyrecommendationPage'" class="weekly-point">{{ $t.getTranslation('LBL_NEW') }}</span>
      </h4>
    </f7-link> -->

    <!--  <f7-link :class="{ active: tabbarLink == 'userGuidePage' }" class="ripple" href="/profile/userguide/" text="User Guide">
      <font-awesome-icon v-if="tabbarLink == 'userGuidePage'" :icon="['fas', 'user-shield']" fixed-width />
      <font-awesome-icon v-else :icon="['fal', 'user-shield']" fixed-width />
      <h4>{{ $t.getTranslation("LBL_USER_GUIDE") }}</h4>
    </f7-link> -->

    <!-- <f7-link :class="{ active: tabbarLink == 'cellarPage' }" href="/cellar/">
      <font-awesome-icon v-if="tabbarLink == 'cellarPage'" :icon="['fas', 'wine-bottle']" fixed-width />
      <font-awesome-icon v-else :icon="['fal', 'wine-bottle']" fixed-width />
      <h4>{{ $t.getTranslation('LBL_MY_CELLAR') }}</h4>
    </f7-link> -->

    <f7-link :class="{ active: tabbarLink == 'profilePage' }" href="/profile/">
      <font-awesome-icon v-if="tabbarLink == 'profilePage'" :icon="['fas', 'user-circle']" fixed-width />
      <font-awesome-icon v-else :icon="['fas', 'user-circle']" fixed-width />
      <h4>{{ $t.getTranslation('LBL_ME') }}</h4>
    </f7-link>

    <f7-link :class="{ active: tabbarLink == 'chat' }" href="/chat/">
      <img v-if="tabbarLink == 'chat'" :src="require('@/assets/images/chat-icon04.png')" style="width: 25px; position: relative; margin-bottom: -6px" loading="lazy" />
      <img v-else :src="require('@/assets/images/chat-icon03.png')" style="width: 25px; position: relative; margin-bottom: -6px" loading="lazy" />
      <h4>
        {{ $t.getTranslation('LBL_LIVE_CHAT') }}
        <span v-if="Unread && tabbarLink != 'chat'" class="new-msg">{{ Unread }}</span>
      </h4>
    </f7-link>
  </f7-toolbar>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from 'vue'
import { useStore } from '@/store'
import { get } from '@/utils/axios'

export default defineComponent({
  name: 'MainToolbarComponent',
  components: {},
  props: {},
  setup() {
    const store = useStore()

    const tabbarLink = computed(() => store.getters['page/getTabbarLink'])

    store.dispatch('config/fetchData', { params: { weeklyProductLogList: 1 } })
    const weeklyProductLog = computed(() => store.getters['config/getData']?.weeklyProductLogList)

    const Unread = computed(() => store.getters['chat/getData']?.Unread)
    const userToken = computed(() => store.getters['user/getToken'])
    const IsViewWeeklyProduct = computed(() => store.getters['user/getData']?.IsViewWeeklyProduct)
    let isLoading = ref(false)

    const checkData = async () => {
      isLoading.value = true
      let ret = await get(`/user/weekly/product/notification?WeeklyProductLogId=${weeklyProductLog.value.WeeklyProductLogId}`)
      store.dispatch('user/setData', { IsViewWeeklyProduct: ret.IsView })
      isLoading.value = false
    }

    onMounted(async () => {
      //call weekly checker..
      store.dispatch('config/fetchData', { force: true })
      if (userToken.value && weeklyProductLog.value) {
        await checkData()
      }
    })

    return {
      tabbarLink,
      Unread,
      weeklyProductLog,
      isLoading,
      IsViewWeeklyProduct,
      store
    }
  }
})
</script>
<style>
.main-toolbar {
  --f7-toolbar-height: 75px;
  --f7-tabbar-labels-height: 75px;
  --f7-tabbar-labels-tablet-height: 75px;
}
.weekly-bg {
  top: -10px;
  right: 20px;
  position: relative;
  width: 0;
  height: 0;
  border-top: 35px solid #a61928;
  border-left: 35px solid transparent;
}
.weekly-point {
  position: absolute;
  top: -40px;
  right: 5px;
  z-index: 2;
  color: #fff;
  font-weight: 700;
  font-size: 10px;
  transform: rotate(45deg);
  width: 20px;
}
.new-msg {
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: red;
  color: #fff;
  font-weight: 600;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: -1;
}
</style>
