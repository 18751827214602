import { helpers } from '@/utils/helpers.js'

const validations = {
  validPassword: function(password) {
    if (password && password.length >= 6 && password.length <= 20) {
      return true
    } else {
      return false
    }
  },
  validEmail: function(email) {
    let regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(email)
  },
  required: (requiredFields, validationData) => {
    for (let i = 0; i < requiredFields.length; i++) {
      let item = requiredFields[i]
      if (helpers.isBlank(validationData[item])) {
        throw {
          Code: 'ERR_MISSING_FIELD',
          Message: `${item} is required`
        }
      }
    }

    return validationData
  },
  requiredFields: (required, check) => {
    let ret = {
      missing: [],
      error: false,
      values: {}
    }

    required.forEach(function(f) {
      if (!check[f]) {
        ret.missing.push(f)
      } else {
        ret.values[f] = check[f]
        delete check[f]
      }
    })

    let keys = Object.keys(check)
    keys.forEach(function(k) {
      ret.values[k] = check[k]
    })

    if (ret.missing.length > 0) {
      throw {
        Code: 'ERR_MISSING_FIELDS',
        Message: 'Required parameters is missing: ' + ret.missing.join(', ')
      }
    }
    return ret.values
  },
  validate: function({ validateData, validationRules, throwError = false, showNotifications = false }) {
    for (let key in validateData) {
      if (key && validationRules[key]) {
        if (validationRules[key].required && !helpers.isBlank(validationRules[key].required) && validationRules[key].required === true) {
          if (helpers.isBlank(validateData[key])) {
            if (throwError) {
              throw {
                Code: 'ERR_MISSING_FIELDS',
                Message: `Error! ${key} field is required.`
              }
            }

            if (showNotifications) {
              helpers.createNotification({
                type: 'error',
                title: 'Error!',
                message: `${key} field is required.`
              })
            }

            return false
          }
        }

        if (validationRules[key].minimum && !helpers.isBlank(validationRules[key].minimum) && validationRules[key].minimum > 0) {
          if (!helpers.isBlank(validateData[key]) && parseFloat(validateData[key]) < validationRules[key].minimum) {
            if (throwError) {
              throw {
                Code: 'ERR_MINIMUM_AMOUNT_INVALID',
                Message: `Error! Minimum amount not met. Minimum amount for ${key} is ${validationRules[key].minimum} characters.`
              }
            }

            if (showNotifications) {
              helpers.createNotification({
                type: 'error',
                title: 'Error!',
                message: `Minimum amount not met. Minimum amount for ${key} is ${validationRules[key].minimum} characters.`
              })
            }

            return false
          }
        }

        if (validationRules[key].maximum && !helpers.isBlank(validationRules[key].maximum) && validationRules[key].maximum > 0) {
          if (!helpers.isBlank(validateData[key]) && parseFloat(validateData[key]) > validationRules[key].maximum) {
            if (throwError) {
              throw {
                Code: 'ERR_MAXIMUM_AMOUNT_INVALID',
                Message: `Error! Maximum amount exceeded. Maximum amount for ${key} is ${validationRules[key].maximum} characters.`
              }
            }

            if (showNotifications) {
              helpers.createNotification({
                type: 'error',
                title: 'Error!',
                message: `Maximum amount exceeded. Maximum amount for ${key} is ${validationRules[key].maximum} characters.`
              })
            }

            return false
          }
        }

        if (validationRules[key].minimumLength && !helpers.isBlank(validationRules[key].minimumLength) && validationRules[key].minimumLength > 0) {
          if (!helpers.isBlank(validateData[key]) && parseInt(validateData[key].length) < validationRules[key].minimumLength) {
            if (throwError) {
              throw {
                Code: 'ERR_MINIMUM_CHARACTER_LENGTH_INVALID',
                Message: `Error! Minimum character length not met. Minimum length for ${key} is ${validationRules[key].minimumLength} characters.`
              }
            }

            if (showNotifications) {
              helpers.createNotification({
                type: 'error',
                title: 'Error!',
                message: `Minimum character length not met. Minimum length for ${key} is ${validationRules[key].minimumLength} characters.`
              })
            }

            return false
          }
        }

        if (validationRules[key].maximumLength && !helpers.isBlank(validationRules[key].maximumLength) && validationRules[key].maximumLength > 0) {
          if (!helpers.isBlank(validateData[key]) && parseInt(validateData[key].length) > validationRules[key].maximumLength) {
            if (throwError) {
              throw {
                Code: 'ERR_MAXIMUM_CHARACTER_LENGTH_INVALID',
                Message: `Error! Maximum character length exceeded. Maximum length for ${key} is ${validationRules[key].maximumLength} characters.`
              }
            }

            if (showNotifications) {
              helpers.createNotification({
                type: 'error',
                title: 'Error!',
                message: `Maximum character length exceeded. Maximum length for ${key} is ${validationRules[key].maximumLength} characters.`
              })
            }

            return false
          }
        }

        if (validationRules[key].pattern && !helpers.isBlank(validationRules[key].pattern) && validationRules[key].pattern.value) {
          let RegexTest = new RegExp(validationRules[key].pattern.value, 'i')
          if (!helpers.isBlank(validateData[key]) && !RegexTest.test(validateData[key])) {
            if (throwError) {
              throw {
                Code: 'ERR_PATTERN_INVALID',
                Message: validationRules[key].pattern.message || `Error! Invalid character patterns.`
              }
            }

            if (showNotifications) {
              helpers.createNotification({
                type: 'error',
                title: 'Error!',
                message: validationRules[key].pattern.message || `Invalid character patterns.`
              })
            }

            return false
          }
        }

        if (validationRules[key].invalid && !helpers.isBlank(validationRules[key].invalid) && validationRules[key].invalid.length > 0) {
          if (!helpers.isBlank(validateData[key]) && validationRules[key].invalid.indexOf(validateData[key]) > -1) {
            if (throwError) {
              throw {
                Code: 'ERR_CONTENT_INVALID',
                Message: validationRules[key].invalid.message || `Error! Invalid content, please refrain from using reserve word.`
              }
            }

            if (showNotifications) {
              helpers.createNotification({
                type: 'error',
                title: 'Error!',
                message: validationRules[key].invalid.message || `Invalid content, please refrain from using reserve word.`
              })
            }

            return false
          }
        }
      }
    }

    return true
  }
}

const install = (app) => {
  app.config.globalProperties.$validations = validations
}

export { install as default, validations }
