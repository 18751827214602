import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    name: 'chat',
    path: '/chat/',
    meta: {
      hideMainToolbar : true,
      isSecured: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "chat" */ /* webpackMode: "lazy" */ '@/pages/chat/View.vue')
  }
]

export default routes
