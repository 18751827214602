import { checkMeta } from '@/routes/@common.js';
const routes = [
  {
    name: 'redirectYouzan',
    path: '/rdyz',
    meta: {
      navigation: {
        isShown: false,
        title: 'redirectYouzan',
        activeLink: 'redirectYouzan',
        tabbarLink: 'redirectYouzan'
      },
      isSecured: false,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "tips" */ /* webpackMode: "lazy" */ '@/pages/redirect/RedirectYouzanPage.vue')
  }
]

export default routes
