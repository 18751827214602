import { configs, extendedConfigs } from '@/utils/configs'
import { Dom7 } from 'framework7'
import { f7 } from 'framework7-vue'
import _ from 'lodash'
import { translation } from '@/utils/translation.js'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

import { useStore } from '@/store'
import { render } from 'vue'
const store = useStore()

const helpers = {
  formatInteger(num) {
    return num.toFixed()
  },
  replaceData(msg, data) {
    let obj = JSON.parse(data)
    let renderMsg = msg
    for (let field in obj) {
      renderMsg = renderMsg.replace('{' + field.toUpperCase() + '}', obj[field])
    }
    return renderMsg
  },
  getShareLink(mode, key) {
    let host = window.location.hostname.toString().indexOf('uat') > -1 || window.location.hostname.toString().indexOf('localhost') > -1 ? 'http://uat.pjfwines.com' : 'https://pjfwines.com'
    if (mode == 'Product') {
      return `${host}/shop/product/view/${key}`
    }
  },
  canAddToCart() {
    // if (window.location.hostname.toString().indexOf('cocoho') > -1) {
    //   return false
    // }
    // return true
    if (extendedConfigs?.canAddToCart) {
      return true
    } else {
      return false
    }
  },
  isAplhaNumeric(val) {
    var regex = /^[0-9a-zA-Z]+$/
    var ret = false
    if (val.match(regex)) {
      ret = true
    } else {
      ret = false
    }
    return ret
  },
  concatAttributeNameCsv(attributes, field) {
    let ret = []
    for (var i = 0; i < attributes.length; i++) {
      let item = attributes[i]
      let name = item[`${field}Name`]
      ret.push(name)
    }
    return ret.join(',')
  },
  cutText(text, length) {
    if (text.split(' ').length > 1) {
      const string = text.substring(0, length)
      const splitText = string.split(' ')
      splitText.pop()
      return splitText.join(' ') + '...'
    } else {
      return text
    }
  },
  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ''
    }
  },
  isMiniProgram() {
    //return extendedConfigs?.miniProgram ? true : false
    return window.__wxjs_environment === 'miniprogram' ? true : false
  },
  isInsideWeixin() {
    var ua = navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true
    } else {
      return false
    }
  },
  isMobile() {
    let ret = false
    // device detection
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      ret = true
    }
    return ret
  },
  padStart(value, numberOfPad, padChar) {
    let pattern = ''
    for (let i = 0; i < numberOfPad; i++) {
      pattern += padChar
    }

    return (pattern + value).slice(-numberOfPad)
  },
  formatMobile({ MobileCode = '+852', MobileNumber }) {
    return `${MobileCode}-${MobileNumber}`
  },
  formatDisplayNumber(number = 0, digits = 2) {
    number = parseFloat(number) || 0

    const name = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' }
    ]

    let regex = /\.0+$|(\.[0-9]*[1-9])0+$/
    let counter

    for (counter = name.length - 1; counter > 0; counter--) {
      if (number >= name[counter].value) {
        break
      }
    }

    return (number / name[counter].value).toFixed(digits).replace(regex, '$1') + name[counter].symbol
  },
  formatLikeCounter(data) {
    let displayLike = data?.TotalLike || 0
    if (extendedConfigs?.isDummyLikeEnabled && data?.TotalDummyLike > 0) {
      displayLike += data.TotalDummyLike
    }

    return helpers.formatDisplayNumber(displayLike)
  },
  formatCommentText(text) {
    return text
  },
  formatDate(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      const languageCode = translation.getLanguage()

      if (languageCode == 'EN') {
        return dayjs(date).format('MMM D, YYYY')
      } else {
        return dayjs(date).format('YYYY[年]M[月]D[日]')
      }
    } else {
      return '--'
    }
  },
  addDate(date, dateType, count) {
    //sample
    //dayjs().add(7, 'day')
    return dayjs(date).add(count, dateType)
  },
  formatDateFromNow(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      return dayjs(date).fromNow()
    } else {
      return '--'
    }
  },
  formatMonthDay(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      return dayjs(date).format('MMM D')
    } else {
      return '--'
    }
  },
  formatDateOrder(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      if (translation.getLanguage() == 'EN') {
        return dayjs(date).format('MMM DD')
      } else {
        return dayjs(date).format('M[月]D[日]')
      }
    } else {
      return '--'
    }
  },
  formatTimeAgo(date) {
    return dayjs(date).fromNow()
  },
  getTaxRate(price, taxRate, currencyCode, currencySign, categoryTaxRate, subSkus) {
    let Tax = taxRate
    if (categoryTaxRate) {
      Tax = categoryTaxRate
    }
    // example:  if wine original price = 1000, glass = 500, bundle price (1 wine + 1 glass) = 1300. wine tax = 19.9%, glass = 9.1%
    //bundle tax = 1000/1500 X 1300 X 17.9% + 500/1500 X1300 X 9.1%
    //bundletax = subskuprice/totalpriceofsubsku x bundletotalprice x categorytaxrate (then total of this will be accumulated to the);

    if (subSkus.length > 0) {
      const totalPriceSubSku = subSkus.reduce((previousValue, currentValue) => previousValue + currentValue.TotalSubSkuPrice, 0)
      const calculateTaxPerItem = subSkus.map((s, index) => {
        let rate = _.filter(s.Category, (r) => {
          let exclude = extendedConfigs?.exlucdeCategoriesforBundle
          if (!exclude.includes(r.CategoryCode)) {
            return r
          }
        })
        return (s.TotalSubSkuPrice / totalPriceSubSku) * (price * (rate[0].TaxRate / 100))
      })
      const totalTax = calculateTaxPerItem.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
      return totalTax
        ? parseFloat(totalTax)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        : '--'
    }

    if (price && parseFloat(price) > 0) {
      let TaxAmount = (Tax / 100) * price
      return (
        (currencyCode ? currencyCode : '') +
        //(currencySign || '$') +
        ' ' +
        (TaxAmount
          ? parseFloat(TaxAmount)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$&,')
          : '--')
      )
    } else {
      return ''
    }
  },
  formatPriceRange(range, currencyCode) {
    let prices = range.replace(/ /g, '').split('-')
    prices = _.map(prices, (r) => {
      return helpers.formatPrice(r)
    })
    return (currencyCode ? currencyCode : '') + ' ' + prices.join('-')
  },
  formatNumber(total) {
    return parseFloat(total)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
      .replace('.00', '')
  },
  formatPrice(price, currencyCode, currencySign) {
    if (currencyCode == 'RMB') {
      currencyCode = '¥'
    }
    if (price && parseFloat(price) > 0) {
      return (
        (currencyCode ? currencyCode : '') +
        //(currencySign || '$') +
        ' ' +
        (price
          ? parseFloat(price)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$&,')
          : '--')
      )
    } else {
      return '--'
    }
  },
  formatDateTime(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      if (translation.getLanguage() == 'EN') {
        return dayjs(date).format('MMM D, YYYY h:mm A')
      } else {
        return dayjs(date).format('YYYY[年]M[月]D[日] h:mm A')
      }
    } else {
      return '--'
    }
  },
  formatTime(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      return dayjs(date).format('h:mm A')
    } else {
      return '--'
    }
  },
  formatSaleTime(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      // return dayjs(date).format('hh:mm:ss')
      return dayjs(date).format('hh:mm')
    } else {
      return '--'
    }
  },
  formatPascalCase(str) {
    return (
      str
        // Look for long acronyms and filter out the last letter
        .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
        // Look for lower-case letters followed by upper-case letters
        .replace(/([a-z\d])([A-Z])/g, '$1 $2')
        // Look for lower-case letters followed by numbers
        .replace(/([a-zA-Z])(\d)/g, '$1 $2')
        .replace(/^./, function(str) {
          return str.toUpperCase()
        })
        // Remove any white space left around the word
        .trim()
    )
  },
  formatFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B'
    }

    const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    const r = 10 ** dp

    do {
      bytes /= thresh
      ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

    return bytes.toFixed(dp) + ' ' + units[u]
  },
  getAttribute(data, LanguageCode, field) {
    if (!data || !data.Attributes) return null

    let { Attributes } = data

    let attribute = _.find(Attributes, (r) => {
      return r.LanguageCode == LanguageCode
    })

    let attributeEN = _.find(Attributes, (r) => {
      return r.LanguageCode == 'EN'
    })

    let val = attribute[field] || attributeEN[field] || null

    if (attribute[field] && attribute[field].indexOf('N/A') > -1 && !(LanguageCode == 'EN')) {
      val = attributeEN[field]
    }

    if (val && field == 'AlcoholLevel') {
      if (!isNaN(val) && parseFloat(val) < 0) {
        val = val * 100
      }

      val = val.toString()
      if (!(val.indexOf('%') > -1)) {
        val += '%'
      }
    }

    return val
  },
  formatConfigValue(str) {
    return helpers.formatPascalCase(str.replace(/^.+\./, ''))
  },
  formatTrimString(str, count = 255, append = '...') {
    if (str && str != '') {
      str = str.replace(/(<([^>]+)>)/gi, '')
      str = str.replace(/&nbsp;/g, ' ')
    }

    if (str && str.length > count) {
      return str.substring(0, count) + append
    } else {
      return str
    }
  },
  onlyNumber(string) {
    if (string) {
      return string.replace(/\D/g, '')
    } else {
      return ''
    }
  },
  isset(obj) {
    if (obj !== null && obj !== undefined) {
      if (typeof obj === 'object' || Array.isArray(obj)) {
        return Object.keys(obj).length
      } else {
        return obj.toString().length
      }
    }

    return false
  },
  isBlank: (value) => {
    if (value === null || value === undefined || value === '' || value.length === 0) {
      return true
    } else {
      return false
    }
  },
  isValidTableId: (val) => {
    return val && parseInt(val) > 0
  },
  isUndefined: (val) => {
    return typeof val === 'undefined'
  },
  isInteger: (val) => {
    return helpers.isUndefined(val) ? false : Number.isInteger(!isNaN(val) ? Number(val) : null)
  },
  isNumeric: (num) => {
    return (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) && !isNaN(num)
  },
  isString: (val) => {
    return typeof val === 'string' || val instanceof String
  },
  isObject: (val) => {
    return !helpers.isUndefined(val) && val.toString() === '[object Object]'
  },
  isArray: (val) => {
    return val instanceof Array
  },
  isEmpty: (val) => {
    return helpers.isBlank(val) || val.length <= 0 || Object.keys(val).length <= 0
  },
  isJSON: function(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }

    return true
  },
  toRaw(obj) {
    return JSON.parse(JSON.stringify(obj))
  },
  randomCharacters(count) {
    count = count || 6
    let text = ''
    let possible = 'ABCDEFGHJKMNOPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz'

    for (let i = 0; i < count; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    return text
  },
  randomNumbers(from, to, length) {
    const numbers = [0]
    for (let i = 1; i < length; i++) {
      numbers.push(Math.ceil(Math.random() * (from - to) + to))
    }

    return numbers
  },
  renderUserName(user) {
    let FullName = []
    if (user.FirstName) FullName.push(user.FirstName)
    if (user.LastName) FullName.push(user.LastName)
    FullName = FullName.join(' ')
    return FullName ? FullName : user.UserName || user.ReferralCode || 'User'
  },
  getRevereFullName(user) {
    let fullName = ''

    if (user && user.LastName) {
      fullName += ` ${user.LastName}`
    }

    if (user && user.FirstName) {
      fullName += user.FirstName
    }

    return fullName
  },
  getFullName(user, showMiddleName = false) {
    let fullName = ''

    if (user && user.FirstName) {
      fullName += user.FirstName
    }

    if (showMiddleName && user && user.MiddleName) {
      fullName += ` ${user.MiddleName}`
    }

    if (user && user.LastName) {
      fullName += ` ${user.LastName}`
    }

    return fullName
  },
  getFullAddress(item) {
    let Address = ''
    if (item && item.Street) {
      Address += item.Street + ', '
    }

    if (item && item.Barangay) {
      Address += (item.Barangay.toLowerCase().indexOf('barangay') > -1 ? '' : 'Barangay ') + item.Barangay + ', '
    }

    if (item && item.City) {
      Address += item.City + (item.Barangay.toLowerCase().indexOf('city') > -1 ? '' : ' City') + ', '
    }

    if (item && item.Province) {
      Address += item.Province + ', '
    }

    if (item && item.Country) {
      Address += item.Country + ', '
    }

    if (item && item.ZipCode) {
      Address += item.ZipCode
    }

    return Address
  },
  renderImageBlob(blob) {
    if (blob) {
      const objectURL = URL.createObjectURL(blob)
      return objectURL
    }
  },
  renderFromFile(file) {
    if (file) {
      let reader = new FileReader()

      reader.onload = (e) => {
        return e.target.result
      }

      reader.readAsDataURL(file)
    }
  },
  encodeFileName(file) {
    return encodeURIComponent(file)
      .replace(/'/g, '%27')
      .replace(/\(/g, '%28')
      .replace(/\)/g, '%29')
  },
  getQRCode(link) {
    return `${configs.baseURL}/webhook/qr/render?link=${helpers.encodeFileName(link)}`
  },
  handleImageError(event, data) {
    event.target.onerror = true // Remove the error handler to prevent an infinite loop
    event.target.src = data.OriginImage
  },
  getImage(item, type, placeholder, isThumb, data) {
    let thumb = isThumb ? `&thumb=1&x-oss-process=image/resize,w_300,m_lfit` : ``
    let name = item?.Image || item?.Name || item || ''

    let fallback = data?.OriginImage ? data.OriginImage : require('@/assets/images/placeholder.jpg')
    let img_src = ''

    if (name == 'pjf-wines-logo.png') {
      name = null
    }

    if (name) {
      if (extendedConfigs?.oss?.enabled) {
        img_src = extendedConfigs.oss?.endpoint + `/${type.toLowerCase()}/${helpers.encodeFileName(name)}?v=1${thumb}`
      }
      img_src = configs.baseURL + `/image?name=${helpers.encodeFileName(name)}&type=${type}${thumb}`
    } else {
      //return placeholder && placeholder !== '' ? placeholder : require('@/assets/images/placeholder.jpg')
      img_src = placeholder && placeholder !== '' ? placeholder : require('@/assets/images/placeholder.jpg')
      //img_src = fallback
    }

    const img = new Image()
    img.src = img_src

    img.onload = () => {
      console.log('Image loaded successfully.')
      // You can do something with the image here
    }
    img.onerror = () => {
      img_src = fallback
    }

    return img_src
  },
  getStyleImage(item, type, placeholder, isThumb, data) {
    let getUrl = helpers.getImage(item, type, placeholder, isThumb, data)
    return `url(${getUrl})`
  },
  getVideo(item, type, placeholder, isThumb) {
    let name = item?.Video || item?.Name || item || ''
    if (name) {
      if (extendedConfigs?.oss?.enabled) {
        return extendedConfigs.oss?.endpoint + `/${type.toLowerCase()}/${helpers.encodeFileName(name)}${isThumb ? `?x-oss-process=video/snapshot,t_1000,f_jpg,w_300` : ``}`
      }
      return configs.baseURL + `${isThumb ? '/video/thumb' : '/video'}?name=${item}&type=${type}`
    } else {
      return placeholder && placeholder !== '' ? placeholder : require('@/assets/images/placeholder.jpg')
    }
  },
  getPoster(filename, isServer) {
    if (isServer || !extendedConfigs?.oss?.enabled) {
      //always return from isServer
      return configs.baseURL + `/video/poster?filename=${filename}`
    }
    return extendedConfigs.oss?.endpoint + `/post/video_poster/${filename}`
  },
  openImage(imageLink) {
    const imageList = [imageLink]
    this.openImageList(imageList)
  },
  objToQueryString(obj) {
    const keyValuePairs = []
    for (let i = 0; i < Object.keys(obj).length; i += 1) {
      if (typeof Object.values(obj)[i] === 'undefined') continue
      keyValuePairs.push(`${encodeURIComponent(Object.keys(obj)[i])}=${encodeURIComponent(Object.values(obj)[i])}`)
    }

    if (keyValuePairs.length == 0) return ''
    return '?' + keyValuePairs.join('&')
  },
  openImageList(imageList) {
    const photoBrowser = f7.photoBrowser.create({
      photos: imageList,
      routableModals: false,
      popupCloseLinkText: '<i class="far fa-fw fa-times"></i>',
      type: 'popup',
      toolbar: imageList.length > 1
    })

    photoBrowser.open()

    photoBrowser.on('close', function() {
      photoBrowser.destroy()
    })
  },
  showLoader() {
    f7?.preloader?.show()
  },
  hideLoader() {
    f7?.preloader?.hide()
  },
  // createToast(text) {
  //   f7.toast.create({
  //     text: text,
  //     position: 'center',
  //     closeTimeout: 2000
  //   })
  // },
  createNotification({ type, title = 'Notification', message = 'Click me to close', time = 'now' }) {
    let notificationCreated = false
    if (!notificationCreated) {
      const notification = f7.notification.create({
        title: configs.title,
        titleRightText: time,
        subtitle: `<strong class="${type}">${title}</strong>`,
        text: message,
        closeTimeout: 3000,
        closeOnClick: true
      })

      notification.open()

      notification.on('open', function() {
        notificationCreated = true
      })

      notification.on('close', function() {
        notification.destroy()
        notificationCreated = false
      })
    }
  },
  createConfirmation({ message, title = 'Confirm', confirm, cancel }) {
    const dialog = f7.dialog.confirm(message, title, confirm, cancel)
  },
  createRemarks({ message, title = 'Confirm', confirm, cancel }) {
    const dialog = f7.dialog.prompt(message, title, confirm, cancel)
  },
  checkFollowDisabled(UserKey) {
    const loginUserInfo = store.getters['user/getData']
    return loginUserInfo.UserKey === UserKey
  },
  catchError(err, showNotifications = false) {
    if (err.Message || err.Code) {
      if (showNotifications) {
        helpers.createNotification({
          type: 'error',
          title: 'Error!',
          message: translation.getTranslation(err.Message || err.Code)
        })
      }
    } else if (err && err.response && err.response.data && (err.response.data.Message || err.response.data.Code)) {
      if (showNotifications) {
        helpers.createNotification({
          type: 'error',
          title: 'Error!',
          message: translation.getTranslation(err.response.data.Message || err.response.data.Code)
        })
      }
    } else if (err && err.message) {
      if (showNotifications && err.message.indexOf(401) <= -1) {
        helpers.createNotification({
          type: 'error',
          title: 'Error!',
          message: err.message
        })
      }
    } else {
      if (showNotifications) {
        helpers.createNotification({
          type: 'error',
          title: 'Error!',
          message: 'Server not reachable, Please try again later.'
        })
      }
    }
  },
  copyToClipBoard(text) {
    var element = document.createElement('textarea')
    element.value = text
    element.setAttribute('readonly', '')
    element.style = { position: 'absolute', left: '-9999px' }

    document.body.appendChild(element)
    element.select()

    document.execCommand('copy')
    document.body.removeChild(element)
  },
  clearFormData(formData) {
    for (let keys in formData) {
      formData[keys] = ''
    }

    return true
  },
  resetForm(formElement) {
    Dom7(formElement)[0].reset()
    setTimeout(() => {
      const inputs = Dom7(formElement).find('.item-input-invalid')

      inputs.forEach((input) => {
        Dom7(input).removeClass('item-input-invalid')
      })
    }, 100)
  },
  deepLinking(f7router, link) {
    if (link && link.indexOf('/shop/merchant/view') > -1) {
      let key = link.split('/')
      key = key[key.length - 1]
      f7router.navigate({ name: 'merchantViewPage', params: { code: key } })
    } else {
      window.open(link, '_blank')
    }
  },
  isUserLogin() {
    return store.getters['user/isLogin']
  },
  userData() {
    return store.getters['user/getData']
  },
  processNamePrivacy(name, privacy) {
    if (privacy == 1) {
      let tmpName = ''
      for (let key in name) {
        if (key == 0) tmpName += name[key]
        tmpName += '*'
      }
      return tmpName
    } else {
      return name
    }
  },
  goBack: _.debounce(() => {
    let history = []
    if (f7?.views?.main?.scrollHistory) {
      history = Object.keys(f7.views.main.scrollHistory)
    }

    window.history.back()

    // if(history.length){
    //   f7.views.main.router.back()
    // }else{
    //   window.history.back();
    // };

    // let isClick = false
    // if (isClick) {
    //   return true
    // }
    // isClick = true
    // if (history.length) {
    //   try {
    //     isClick = false
    //     return f7.views.main.router.back()
    //   } catch (err) {
    //     let current = window.location.href.replace(window.location.origin, '')
    //     let getPosition = history.indexOf(current)
    //     let lastElement = history[getPosition - 1]
    //     //return f7.views.main.router.navigate(lastElement);
    //     isClick = false
    //     window.location.href = lastElement
    //   }
    // } else {
    //   let current = window.location.href.replace(window.location.origin, '')
    //   let lastElement = '/shop'
    //   if (current.indexOf('/shop/') > -1) {
    //     lastElement = '/shop/'
    //   } else if (current.indexOf('/home/') > -1) {
    //     lastElement = '/home/explorepjf/'
    //   }
    //   isClick = false
    //   window.location.href = lastElement
    // }
  }, 500)
}

const install = (app) => {
  app.config.globalProperties.$h = helpers
}

export { install as default, helpers }
