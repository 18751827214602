import { helpers } from '@/utils/helpers'
import { socket } from '@/utils/socket.js'

const data = {
  Unread: 0,
  NotificationCount: 0,
  NotificationLatest: []
}

export const state = {
  data
}

export const getters = {
  getData: (state) => {
    return state.data
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  },
  RESET_DATA(state) {
    state.data = { ...data }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  resetData: (context) => {
    context.commit('RESET_DATA')
  },
  connect: async () => {
    if (helpers.isUserLogin()) {
      let UserData = helpers.userData()
      if (UserData?.UserKey) {
        socket.join(UserData.UserKey)
      }
    }
  }
}

export const chat = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
