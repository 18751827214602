<template>
  <div v-if="banner.length > 0 && show" class="popup-banner-mini">
    <div class="close-container" @click="closeBanner">
      <font-awesome-icon :icon="['far', 'times']" fixed-width />
    </div>

    <div
      class="banner-image-c"
      :style="{
        'background-image': 'url(' + $h.getImage(banner[0]?.Image, 'BANNER') + ')'
      }"
      @click="openLink()"
    ></div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import { extendedConfigs } from '@/utils/configs.js'
import { $HTTP, post } from '@/utils/axios'
import { useStore } from '@/store'
import { helpers } from '@/utils/helpers.js'

export default defineComponent({
  name: 'PopupMiniBannerComponent',
  components: {},
  props: {
    show: Boolean,
    type: String,
    divider: Boolean,
    size: { type: Number, default: 6 },
    title: String,
    url: String
  },
  setup(props) {
    const store = useStore()

    const currentLanguageCode = computed(() => store.getters['translation/getLanguage'])

    const popupMiniStatus = computed(() => store.getters['config/getData']?.isPopupMini)
    const popupMiniDate = computed(() => store.getters['config/getData']?.popupMiniDate)

    const banner = ref([])
    const isOpened = ref(false)

    let isWeChat = helpers.isInsideWeixin()
    console.log('wx', wx)

    const getData = async () => {
      try {
        let res = await $HTTP.get(`/banner/list?page=1&size=1&LanguageCode=${currentLanguageCode.value}&Type=POPUPBANNERMINI`)
        if (res?.data?.data?.data && popupMiniStatus.value) {
          banner.value = res?.data?.data?.data
        }

        if (popupMiniStatus.value) {
          store.dispatch('config/setData', {
            isPopupMini: true,
            popupMiniDate: null
          })
        }

        return
      } catch (err) {
        helpers.catchError(err, true)
      }
    }

    const closeBanner = () => {
      store.dispatch('config/setData', {
        isPopupMini: false,
        popupMiniDate: new Date()
      })
      banner.value = []
    }

    const openLink = async () => {
      let link = banner.value[0]?.Link
      if (!link || link == '') return

      if (isWeChat) {
        // TRIAL #1: TRY NAVIGATE TO NAVIGATE USING SPECIFIED LINK
        // wx.miniProgram.navigateTo({
        //   appId: 'wxf64ebb3b425666ed'
        // })

        wx.miniProgram.navigateTo({
          url: '/pages/pjfwines/mini?id=wxf64ebb3b425666ed'
        })

        // TRIAL #2:
        // let share = await post("/webhook/wechat/share", { url: link });

        // wx.config({
        //   appId: share.appid,
        //   timestamp: share.timestamp,
        //   nonceStr: share.random,
        //   signature: share.signature,
        //   jsApiList: ['scanQRCode']
        // })

        // wx.scanQRCode({
        //   needResult: 1,
        //   scanType: ['qrCode'],
        //   success: function(res) {
        //     let link = res.resultStr
        //     wx.miniProgram.navigateTo({
        //       url: link
        //     })
        //   }
        // })
      } else {
        window.location.href = link
      }
    }

    const closeDialog = () => {
      isOpened.value = false
    }

    onMounted(() => {
      let additionalDate = null
      let currentDate = new Date().getTime()
      if (popupMiniDate.value) {
        additionalDate = helpers.addDate(popupMiniDate.value, 'minutes', extendedConfigs.popupBannerTimer)
        additionalDate = new Date(additionalDate).getTime()
      }

      if (extendedConfigs.popupBannerMiniShow && currentDate >= additionalDate) {
        getData()
      }
    })

    return {
      isOpened,
      closeDialog,
      banner,
      closeBanner,
      openLink,
      store,
      extendedConfigs
    }
  }
})
</script>

<style>
.popup-banner-mini {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99999;

  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-banner-mini .banner-image-c {
  width: 100%;
  height: 90%;
  background-color: transparent;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.popup-banner-mini img {
  cursor: pointer;
  height: 90%;
  width: auto;
}

.popup-banner-mini .close-container {
  background: #fff;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  color: #757575;
}
</style>
