import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    name: 'referral',
    path: '/referral/',
    meta: {
      hideMainToolbar : true,
      isSecured: false,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "referral" */ /* webpackMode: "lazy" */ '@/pages/auth/Referral.vue')
  },
  {
    name: 'referralView',
    path: '/ref/:code',
    meta: {
      hideMainToolbar : false,
      isSecured: false,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "referral-view" */ /* webpackMode: "lazy" */ '@/pages/auth/ReferralView.vue')
  }
]

export default routes
