import dayjs from 'dayjs'

import { $HTTP } from '@/utils/axios'
import { helpers } from '@/utils/helpers'
import { configs } from '@/utils/configs'
import { socket } from '@/utils/socket.js'

const data = {
  Token: '',
  TokenStartDate: '',
  IsRememberMe: 0,
  Expiration: '',
  ProductCode: null,
  KolCode: null,
  PostKey: null,
  IsWechatSteps: false,
  IsCompleted: false,
  UserKey: null,
  IsConcierge: 0,
  SurveyKey: null,
  IsViewWeeklyProduct: true,
  IsNewUser: 1,
  IsNewUserBanner: true,
  Country: {
    CountryCode: '',
    RegionId: '',
    SubRegionId: ''
  }
}

export const state = {
  data
}

export const getters = {
  getToken: (state) => {
    const expirationTime = state?.data?.IsRememberMe === 1 ? configs.tokenExpirationRememberMe : configs.tokenExpiration
    const expirationDate = state.data.TokenStartDate + expirationTime
    const currentDate = dayjs().valueOf()

    let isExpired = currentDate > expirationDate
    if (isExpired) {
      state.data.Token = ''
      state.data.TokenStartDate = ''
      return ''
    } else {
      return state.data.Token
    }
  },
  getData: (state) => {
    return state.data
  },
  isLogin: (state) => {
    return state?.data?.Token && state?.data?.Token !== '' ? true : false
  },
  isPjfOfficial: (state) => {
    return state?.data?.Token && state?.data?.Influencer?.IsPjfOfficial == 1 ? true : false
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  },
  RESET_DATA(state) {
    state.data = { ...data }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  resetData: (context) => {
    context.commit('RESET_DATA')
  },
  connect: ({ state }) => {
    if (state?.data?.UserKey) {
      socket.join(state.data.UserKey)
    }
  },
  fetchData: async (context, payload) => {
    const userToken = context.state.data.Token
    const expirationDate = context.state.data.Expiration
    const currentDate = dayjs().valueOf()

    let isExpired = currentDate > expirationDate
    if (payload?.force) {
      isExpired = true
    }

    if (userToken && userToken !== '') {
      if (expirationDate == '' || isExpired) {
        helpers.showLoader('Getting user information...')

        try {
          const res = await $HTTP.get('/user/info')

          if (res && res.status === 200 && res.data && res.data.data) {
            helpers.hideLoader()

            const userInfo = res.data.data
            userInfo.Expiration = dayjs()
              .add(30, 'minute')
              .valueOf()

            context.commit('UPDATE_DATA', userInfo)
            return
          }

          // NO DATA RETURN SHOULD LOGOUT USER
          context.commit('UPDATE_DATA', data)
          throw new Error('Server not responding, Please try again later.')
        } catch (err) {
          helpers.hideLoader()
          helpers.catchError(err, true)
        }
      } else {
        return
      }
    }
  }
}

export const user = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
