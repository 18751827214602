import { $HTTP } from '@/utils/axios'
import { helpers } from '@/utils/helpers'
import { translation } from '@/utils/translation'

import { useStore } from '@/store'
const store = useStore()

const storeToken = async (domain, token) => {
  //domain == window.location.host &&
  if (token) {
    let returnData = {
      Token: token
    }
    returnData.TokenStartDate = new Date().getTime()
    store.dispatch('user/setData', returnData)

    const res = await $HTTP.get('/user/info')
    if (res?.data?.data?.UserKey) {
      store.dispatch('user/setData', { UserKey: res.data.data.UserKey })
      store.dispatch('user/connect')
    }
    if (res?.data?.data?.Wechat) {
      store.dispatch('user/setData', { Wechat: res.data.data.Wechat })
    }
  }
}

const redirectDomain = async (resolve, to, domain, isOrigin) => {
  const userToken = store.getters['user/getToken']
  const page = store.getters['page/getData']
  const currentLanguageCode = store.getters['translation/getLanguage']

  const protocol = window.location.protocol !== 'https:' ? 'http' : 'https'
  let pageViewTracker = page.pageViewTracker || null

  if (window.location.host != domain) {
    let identifier = '?'
    if (to.url.indexOf('?') > -1) identifier = '&'

    let url = isOrigin ? `${protocol}://${domain}${to.url}` : `${protocol}://${domain}${to.url}${userToken && userToken !== '' ? `${identifier}token=${userToken}` : ''}`
    url = `${url}${url.indexOf('?') > -1 ? '&' : '?'}lang=${currentLanguageCode}`

    if (isOrigin) {
      //if going back to origin domain logout shop domain
      store.dispatch('user/resetData') //dispatch reset data before redirection
    }

    return (window.location.href = url)
  }

  //before resolve if splash page add class and indicator
  if (to.name == 'splash') {
    document.getElementById('app').classList.add('splash-render-page')
    store.dispatch('page/setData', { isSplash: true })
  }

  if (!pageViewTracker) {
    //await before resolve
    pageViewTracker = helpers.randomCharacters(20)
    await store.dispatch('page/setData', { pageViewTracker })
  }

  resolve()

  //after resolve check which page and post the page view
  if (!(to.name == 'splash' || to.name == 'loginPage')) {
    //post now, no need to wait for the request, return the function
    const userData = store.getters['user/getData']
    const Bale = {
      pageViewTracker,
      IsMobile: helpers.isMobile(),
      IsWechat: helpers.isInsideWeixin(),
      UserKey: userToken && userData?.UserKey ? userData.UserKey : null,
      Url: to.url
    }
    $HTTP.post('/page/view', Bale)
  }

  return true
}

const redirectToHome = (resolve, to) => {
  const splash = store.getters['page/getSplashStatus']
  return (window.location.href = '/home')
  resolve()
  return true
}

const redirectToSplash = (resolve, to) => {
  return (window.location.href = '/splash/')
  resolve()
  return true
}

const login = async (apiLink, formData) => {
  let res = await $HTTP.post(apiLink, formData)

  if (res && res.status === 200 && res?.data?.data && res?.data?.data.Token != '') {
    let returnData = res.data.data
    returnData.TokenStartDate = new Date().getTime()
    store.dispatch('user/setData', returnData)
    store.dispatch('chat/connect')
    store.dispatch('cart/fetchData', { count: true })
    store.dispatch('user/fetchData', { force: false })
    return true
  }
  return false
}

const checkHasRoles = (Roles) => {
  const userRoles = store.getters['user/getData'].UserRoleList

  if (Roles && Roles.length > 0) {
    let hasRolesCounter = 0

    Roles.forEach((role) => {
      if (userRoles.indexOf(role) > -1) {
        hasRolesCounter++
      }
    })

    return hasRolesCounter === Roles.length
  } else {
    return true
  }
}

const checkHasAnyRoles = (Roles) => {
  const userRoles = store.getters['user/getData'].UserRoleList

  if (Roles && Roles.length > 0) {
    let hasRolesCounter = 0

    Roles.forEach((role) => {
      if (userRoles.indexOf(role) > -1) {
        hasRolesCounter++
      }
    })

    return hasRolesCounter > 0
  } else {
    return true
  }
}

const logout = ({ f7router, loginLink = '/login/' }) => {
  helpers.createConfirmation({
    message: translation.getTranslation('LBL_CONFIRM_LOGOUT'),
    confirm: () => {
      store.dispatch('user/resetData')
      store.dispatch('chat/resetData')
      if (f7router && loginLink) {
        f7router.navigate(loginLink)
      } else {
        //window.location.href = loginLink
        window.location.href = '/home'
      }
    }
  })
}

export { login, logout, redirectDomain, storeToken, redirectToHome, redirectToSplash }
