import { library } from '@fortawesome/fontawesome-svg-core'

// DECLARATION FOR SOLID ICONS
import {
  faHeart as fasHeart,
  faEye as fasEye,
  // faStar as fasStar,
  // faStarHalfAlt,
  faHomeLg as fasHomeLg,
  faStoreAlt as fasStoreAlt,
  faWineBottle as fasWineBottle,
  faUserShield as fasUserShield,
  faUserCircle as fasUserCircle,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faTh,
  faThList as fasThList,
  faSeedling,
  faStickyNote,
  faCheckCircle,
  faInfoCircle,
  // faCommentsAlt,
  // faEnvelope,
  faAngleUp,
  faMobile,
  faPaperPlane,
  // faBoxOpen,
  // faSyncAlt,
  // faCamera,
  // faQrcode,
  faPlay,
  faCalendar
} from '@fortawesome/pro-solid-svg-icons'

// DECLARATION FOR REGULAR ICONS
import {
  faBars,
  faSearch,
  faShoppingCart,
  faSignOut,
  faTimes,
  faSync,
  faExchangeAlt,
  faTrash,
  faImages,
  faEllipsisH,
  // faCartPlus,
  faStar,
  faHeart,
  faComments,
  faShare,
  faArrowLeft,
  faFileDownload,
  faArrowUp,
  faArrowDown,
  // faArrowRight,
  faFilter,
  faCog,
  faBells,
  faCalendarStar,
  faTags,
  faEdit,
  faPlus,
  faEllipsisV,
  faVideo,
  faLanguage,
  faComment,
  faEye,
  // PROFILE PAGE
  faCreditCard,
  faAnchor,
  faHeadset,
  faUsers,
  faStars,
  faCopy,
  faWineGlass,
  faTicket,
  faMapMarked,
  faThList as fadThList,
  faMoneyCheckEditAlt,
  faShippingFast,
  faBoxCheck,
  faPen,
  // faAngleRight,
  faExclamationTriangle,
  faLightbulbOn,
  faExpand
} from '@fortawesome/pro-regular-svg-icons'

// DECLARATION FOR LIGHT ICONS
import {
  // faHomeLg,
  // faStoreAlt,
  faBarcodeRead,
  faUserShield,
  faWineBottle
  // faUserCircle,
  // faPhotoVideo
  // faLayerPlus,
  // faVideoPlus
} from '@fortawesome/pro-light-svg-icons'

// DECLARATION FOR BRAND ICONS
import {
  faWeixin,
  faAlipay
  // faFacebookSquare,
  // faWhatsappSquare
} from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// INCLUDE TO LIBRARY FOR SOLID ICONS
library.add(
  fasHeart,
  fasEye,
  // fasStar,
  // faStarHalfAlt,
  fasHomeLg,
  fasStoreAlt,
  fasWineBottle,
  fasUserShield,
  fasUserCircle,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faTh,
  fasThList,
  faSeedling,
  faStickyNote,
  faCheckCircle,
  faInfoCircle,
  // faCommentsAlt,
  // faEnvelope,
  faAngleUp,
  faMobile,
  faPaperPlane,
  // faBoxOpen,
  // faSyncAlt,
  // faCamera,
  // faQrcode,
  faPlay,
  faCalendar
)

// INCLUDE TO LIBRARY FOR REGULAR ICONS
library.add(
  faBars,
  faSearch,
  faShoppingCart,
  faSignOut,
  faTimes,
  faSync,
  faExchangeAlt,
  faTrash,
  faImages,
  faEllipsisH,
  // faCartPlus,
  faStar,
  faHeart,
  faComments,
  faShare,
  faArrowLeft,
  faFilter,
  faCog,
  faBells,
  faCalendarStar,
  faTags,
  faEdit,
  faPlus,
  faEllipsisV,
  faVideo,
  faLanguage,
  faComment,
  faEye,
  faCreditCard,
  faAnchor,
  faHeadset,
  faUsers,
  faStars,
  faFileDownload,
  faCopy,
  faWineGlass,
  faTicket,
  faMapMarked,
  fadThList,
  faMoneyCheckEditAlt,
  faShippingFast,
  faBoxCheck,
  faArrowUp,
  faArrowDown,
  faPen,
  // faAngleRight,
  faCalendar,
  faExclamationTriangle,
  faLightbulbOn,
  faExpand
)

// INCLUDE TO LIBRARY FOR LIGHT ICONS
library.add(
  // faHomeLg,
  //  faStoreAlt,
  faBarcodeRead,
  faUserShield,
  faWineBottle
  // faUserCircle,
  // faPhotoVideo,
  // faLayerPlus,
  // faVideoPlus
)

//INCLUDE TO LIBRARY FOR BRANDS
library.add(
  faWeixin,
  faAlipay
  // faFacebookSquare,
  // faWhatsappSquare
)

//INCLUDE TO LIBRARY FOR DUOTONES

export { FontAwesomeIcon }
