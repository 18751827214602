import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    name: 'influencerPage',
    path: '/influencer/',
    meta: {
      navigation: {
        isShown: true,
        title: 'Influencer',
        activeLink: 'influencerPage',
        tabbarLink: 'influencerPage'
      },
      isSecured: false,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "influencer" */ /* webpackMode: "lazy" */'@/pages/influencer/InfluencerListPage.vue'),
    routes: [
      {
        name: 'influencerViewPage',
        path: 'view/:code',
        meta: {
          navigation: {
            isShown: false,
            title: 'Influencer View',
            activeLink: 'influencerViewPage',
            tabbarLink: 'homePage'
          },
          isSecured: false,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "influencer-view" */ /* webpackMode: "lazy" */'@/pages/influencer/InfluencerViewPage.vue')
      }
    ]
  }
]

export default routes
