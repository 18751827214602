import { post } from '@/utils/axios'

const data = {
  facebook: [],
  whatsapp: [],
  sms: [],
  email: []
}

export const state = {
  data
}

export const getters = {
  getData: (state) => {
    return state.data
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  },
  RESET_DATA(state) {
    state.data = { ...data }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  increase: async (context, payload) => {
    const shareInfo = context?.state?.data[payload?.network]

    if (shareInfo && shareInfo.indexOf(payload?.linkCode) > -1) {
      return true
    } else {
      // UPDATE Session payloads
      let updatePayload = {}
      shareInfo.push(payload?.linkCode)
      updatePayload[payload.network] = shareInfo
      context.commit('UPDATE_DATA', updatePayload)

      // POST to server to increase counter
      await post('/share/increase', payload)
    }
  }
}

export const share = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
