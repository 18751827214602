import { helpers } from '@/utils/helpers'
import { get } from '@/utils/axios'

import _ from 'lodash'

const data = {
  CartKey: null,
  CartItems: [],
  CHECKOUT_ITEMS: []
}

export const state = {
  data
}

export const getters = {
  getData: (state) => {
    return state.data
  },
  getTotal: (state) => {
    let total = 0
    _.each(state.data.CartItems, (it) => {
      total += it.Qty
    })
    return total
  },
  getItems: (state) => {
    return state.data.CartItems
  },
  getGroupedItems: (state) => {
    let Items = {}

    _.each(state.data.CartItems, (r) => {
      if (!Items[r.SupplierCode]) Items[r.SupplierCode] = []
      Items[r.SupplierCode].push(r)
    })

    Items = _.map(Items, (r, index) => {
      return {
        SupplierCode: index,
        Items: r
      }
    })

    return _.filter(Items, (r) => {
      return r.Items.length
    })
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  },
  RESET_DATA(state) {
    state.data = { ...data }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  resetData: (context) => {
    context.commit('RESET_DATA')
  },
  fetchData: async (context, payload) => {
    //if payload is all, means get items not just count
    if (helpers.isUserLogin() && payload.all) {
      try {
        const res = await get('/cart/view')
        context.commit('UPDATE_DATA', { CartKey: res.Cart.CartKey, CartItems: res.Cart.ItemList })
      } catch (err) {
        context.commit('UPDATE_DATA', data)
      }
    }
  }
}

export const cart = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
