import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    name: 'notificationsPage',
    path: '/notifications/',
    meta: {
      navigation: {
        isShown: true,
        title: 'notifications',
        activeLink: 'notificationsPage',
        tabbarLink: 'notificationsPage'
      },
      isSecured: true,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "notifications" */ /* webpackMode: "lazy" */'@/pages/notifications/NotificationsPage.vue')
  },
  {
    name: 'viewNotification',
    path: '/notifications/view/:key',
    meta: {
      navigation: {
        isShown: true,
        title: 'notifications',
        activeLink: 'notificationsPage',
        tabbarLink: 'notificationsPage'
      },
      isSecured: true,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "notifications-view" */ /* webpackMode: "lazy" */'@/pages/notifications/ViewNotificationPage.vue')
  }
]

export default routes
