<template>
  <!-- <div class="popup-coupon" v-if="show && isOpened">
    <div style="background: #fff; position: relative; padding: 20px; width: 80%">
      <div class="close-container" @click="closeDialog">
        <font-awesome-icon :icon="['far', 'times']" fixed-width />
      </div>
      <p style="text-align: center; margin-top: 30px">
        {{ $t.getTranslation("LBL_PLEASE_OPEN_IN_WECHAT_BROWSER") }}
      </p>
      <f7-button @click="openWechat" style="margin-top: 20px" fill large raised>{{ $t.getTranslation("BTN_TRIGGER_WECHAT") }}</f7-button>
    </div>
  </div> -->

  <div v-if="banner.length > 0 && show" class="popup-coupon">
    <div class="close-container" @click="closeBanner">
      <font-awesome-icon :icon="['far', 'times']" fixed-width />
    </div>

    <!-- <img @click="openLink()" :src="require('@/assets/images/coupons/300X800.png')"> -->

    <!-- <template v-if="extendedConfigs?.miniProgram">
      <div
        v-if="$t.getLanguage() == 'EN'"
        class="banner-image-c"
        :style="{
          'background-image': 'url(' + require('@/assets/images/popup/mini-en.jpg') + ')',
        }"
        @click="openMiniProgram()"
      ></div>
      <div
        v-else
        class="banner-image-c"
        :style="{
          'background-image': 'url(' + require('@/assets/images/popup/mini-chs.jpg') + ')',
        }"
        @click="openMiniProgram()"
      ></div>
    </template> -->

    <div
      class="banner-image-c"
      :style="{
        'background-image': 'url(' + $h.getImage(banner[0]?.Image, 'BANNER') + ')'
      }"
      @click="openLink()"
    ></div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, inject, computed } from 'vue'
import { configs, extendedConfigs } from '@/utils/configs.js'
import { $HTTP } from '@/utils/axios'
import { useStore } from '@/store'
import { helpers } from '@/utils/helpers.js'

export default defineComponent({
  name: 'PopupBannerComponent',
  components: {},
  props: {
    show: Boolean,
    type: String,
    divider: Boolean,
    size: { type: Number, default: 6 },
    title: String,
    url: String
  },
  setup(props) {
    const $t = inject('$translation')
    const store = useStore()

    const currentLanguageCode = computed(() => store.getters['translation/getLanguage'])

    const popupStatus = computed(() => store.getters['config/getData']?.isPopup)
    const popupDate = computed(() => store.getters['config/getData']?.popupDate)

    const isNewUser = computed(() => store.getters['user/getData']?.IsNewUser)
    const userToken = computed(() => store.getters['user/getData']?.Token)

    const banner = ref([])
    const isOpened = ref(false)
    let isRedirect = ref(false)

    const getData = async () => {
      try {
        let res = await $HTTP.get(`/banner/list?page=1&size=1&LanguageCode=${currentLanguageCode.value}&Type=POPUPBANNER`)
        if (res?.data?.data?.data && popupStatus.value && !isRedirect.value) {
          banner.value = res?.data?.data?.data
        }

        if (popupStatus.value) {
          store.dispatch('config/setData', {
            isPopup: true,
            popupDate: null
          })
        }

        if (userToken.value) {
          banner.value = []
        }

        return
        throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
      } catch (err) {
        helpers.catchError(err, true)
      }
    }

    const closeBanner = () => {
      store.dispatch('config/setData', {
        isPopup: false,
        popupDate: new Date()
      })
      banner.value = []
    }

    const openLink = () => {
      let link = banner.value[0]?.Link
      window.location.href = link
    }

    const closeDialog = () => {
      isOpened.value = false
    }

    const openWechat = () => {
      isOpened.value = false
      window.location.href = 'weixin://'
    }

    onMounted(() => {
      if (helpers.isMobile() && !helpers.isInsideWeixin()) {
        //isOpened.value = true;
      }

      const urlParams = new URLSearchParams(location.search)
      for (const [key, value] of urlParams) {
        if (key == 'r' && value != '') {
          isRedirect.value = true
        }
      }

      let additionalDate = null
      let currentDate = new Date().getTime()
      if (popupDate.value) {
        additionalDate = helpers.addDate(popupDate.value, 'minutes', extendedConfigs.popupBannerTimer)
        additionalDate = new Date(additionalDate).getTime()
      }
      if (extendedConfigs.popupBannerShow && currentDate >= additionalDate) {
        getData()
      }
    })

    const openMiniProgram = () => {
      wx.miniProgram.navigateTo({
        url: '/pages/pjfwines/mini?id=wxf64ebb3b425666ed'
      })
    }

    return {
      isOpened,
      openWechat,
      closeDialog,
      banner,
      closeBanner,
      openLink,
      store,
      extendedConfigs,
      openMiniProgram
    }
  }
})
</script>

<style>
.popup-coupon {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99999;

  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-coupon .banner-image-c {
  width: 100%;
  height: 90%;
  background-color: transparent;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.popup-coupon img {
  cursor: pointer;
  height: 90%;
  width: auto;
}

.popup-coupon .close-container {
  background: #fff;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  color: #757575;
}
</style>
