import { checkMeta } from '@/routes/@common.js';
const routes = [
  {
    name: 'contact',
    path: '/contact/',
    meta: {
      navigation: {
        isShown: false,
        title: 'contact',
        activeLink: 'contact',
        tabbarLink: 'contactPage'
      },
      isSecured: false,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "contact" */ /* webpackMode: "lazy" */ '@/pages/contact/ContactPage.vue')
  },
  {
    name: 'merchantContactpage',
    path: '/contact/merchant/',
    meta: {
      navigation: {
        isShown: true,
        title: 'contact',
        activeLink: 'contactPage',
        tabbarLink: 'contactPage'
      },
      isSecured: false,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "contact-merchant" */ /* webpackMode: "lazy" */ '@/pages/contact/ContactMerchantPage.vue')
  }
]

export default routes
