<template>
  <f7-panel id="leftPanel" left v-if="!isSplash" :visible-breakpoint="2200">
    <f7-page>
      <f7-block class="logo">
        <img loading="lazy" src="@/assets/images/logo/logo-name-color.svg" alt="Logo" />
      </f7-block>

      <div v-if="$h.isUserLogin()" class="notifications">
        <div class="title">
          <font-awesome-icon :icon="['far', 'bells']" fixed-width />
          <h1>{{ $t.getTranslation("LBL_NOTIFICATIONS") }}</h1>
          <span class="circle"></span>
        </div>

        <template v-if="NotificationLatest.length">
          <ul>
            <template v-for="item in NotificationLatest" :key="item.NotificationId">
              <NotificationNavigationComponent :item="item" />
            </template>
          </ul>

          <f7-button href="/notifications/" panel-close class="no-ripple viewall">
            <span>{{ $t.getTranslation("LBL_VIEW_ALL") }}</span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" fixed-width />
          </f7-button>
        </template>

        <template v-else>
          <p class="no-data">{{ $t.getTranslation("LBL_NOTIFICATIONS_EMPTY") }}</p>
        </template>
      </div>

      <div class="notifications">
        <div class="title">
          <font-awesome-icon :icon="['far', 'calendar-star']" fixed-width />
          <h1>{{ $t.getTranslation("LBL_EVENTS") }}</h1>
          <span class="circle"></span>
        </div>

        <template v-if="eventList && eventList.length > 0">
          <ul>
            <li v-for="event in eventList" :key="'evt_' + event.EventId">
              <f7-link panel-close class="no-ripple" :href="'/events/view/' + event.EventCode">{{ event.Title || event.EventCode }}</f7-link>
              <span class="circle"></span>
            </li>
          </ul>
          <f7-button href="/events/" panel-close class="no-ripple viewall">
            <span>{{ $t.getTranslation("LBL_VIEW_ALL") }}</span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" fixed-width />
          </f7-button>
        </template>

        <template v-else>
          <p class="no-data">{{ $t.getTranslation("LBL_EVENTS_EMPTY") }}</p>
        </template>
      </div>

      <div class="notifications">
        <div class="title">
          <font-awesome-icon :icon="['far', 'tags']" fixed-width />
          <h1>{{ $t.getTranslation("LBL_PROMOTIONS") }}</h1>
          <span class="circle"></span>
        </div>

        <template v-if="promotionsList && promotionsList.length > 0">
          <ul v-for="promotion in promotionsList" :key="'prm_' + promotion.CouponId">
            <li>
              <f7-link class="no-ripple" @click="cartPage()">{{ promotion.Name }}</f7-link>
            </li>
          </ul>

          <f7-button href="/discounts/" panel-close class="no-ripple viewall">
            <span>{{ $t.getTranslation("LBL_VIEW_ALL") }}</span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" fixed-width />
          </f7-button>
        </template>

        <template v-else>
          <p class="no-data">{{ $t.getTranslation("LBL_PROMOTIONS_EMPTY") }}</p>
        </template>
      </div>

      <f7-link class="no-ripple" href="/survey/" panel-close>
        <div class="notifications">
          <div class="title">
            <font-awesome-icon :icon="['far', 'pen']" fixed-width />
            <h1>{{ $t.getTranslation("LBL_SURVEY") }}</h1>
            <span class="circle"></span>
          </div>
        </div>
      </f7-link>

      <!-- <f7-link class="no-ripple" href="/qrscan/" panel-close>
        <div class="notifications">
          <div class="title">
            <font-awesome-icon :icon="['fa', 'qrcode']" fixed-width />
            <h1>{{ $t.getTranslation("LBL_QR") }}</h1>
            <span class="circle"></span>
          </div>
        </div>
      </f7-link> -->

      <f7-link class="no-ripple" href="/profile/userguide/" panel-close>
        <div class="notifications">
          <div class="title">
            <font-awesome-icon :icon="['fas', 'user-shield']" fixed-width />
            <h1>{{ $t.getTranslation("LBL_USER_GUIDE") }}</h1>
            <span class="circle"></span>
          </div>
        </div>
      </f7-link>

      <f7-link class="no-ripple" href="/contact/" panel-close>
        <div class="notifications">
          <div class="title">
            <font-awesome-icon :icon="['fas', 'mobile']" fixed-width />
            <h1>{{ $t.getTranslation("LBL_CONTACT_US") }}</h1>
            <span class="circle"></span>
          </div>
        </div>
      </f7-link>

      <f7-link class="no-ripple" href="/about/" panel-close>
        <div class="notifications">
          <div class="title">
            <h1>{{ $t.getTranslation("LBL_ABOUT_US") }}</h1>
            <span class="circle"></span>
          </div>
        </div>
      </f7-link>

      <f7-link class="no-ripple language" smart-select :smart-select-params="smartSelectParams">
        {{ $t.getTranslation("LBL_LANGUAGE") }}
        <font-awesome-icon :icon="['far', 'language']" fixed-width />

        <select name="languageList">
          <option v-for="language in languageList" :key="'lg_' + language.Code" :value="language.Code" :selected="currentLanguageCode == language.Code" panel-close>{{ language.Name }}</option>
        </select>
      </f7-link>

      <f7-link v-if="$h.isUserLogin()" class="no-ripple logout" panel-close @click="logout">
        {{ $t.getTranslation("LBL_SIGN_OUT") }}
        <font-awesome-icon :icon="['far', 'sign-out']" fixed-width />
      </f7-link>
    </f7-page>
  </f7-panel>
</template>

<script>
import { ref, computed, onMounted, inject, defineAsyncComponent } from "vue";
import { f7 } from "framework7-vue";
import { get } from "@/utils/axios";
import { useStore } from "@/store";
import { logout } from "@/logics/auth.js";

// import NotificationNavigationComponent from "@/components/navigations/NotificationNavigationComponent.vue";

const NotificationNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "notification-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/NotificationNavigationComponent.vue"));

export default {
  name: "PanelLeftComponent",
  components: { NotificationNavigationComponent },
  props: {},
  setup(props) {
    const $t = inject("$translation");

    const store = useStore();

    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);

    store.dispatch("config/fetchData", { params: { languageList: 1 } });
    const languageList = computed(() => store.getters["config/getData"]?.languageList);

    const NotificationLatest = computed(() => store.getters["chat/getData"]?.NotificationLatest);
    const isSplash = computed(() => store.getters["page/getSplashStatus"]);

    const eventList = ref([]);
    const promotionsList = ref([]);

    const smartSelectParams = {
      openIn: "popover",
      routableModals: false,
      closeOnSelect: true,
      on: {
        closed: async (smartSelect) => {
          let languageCode = smartSelect?.$selectEl?.val();

          store.dispatch("translation/setLanguage", { languageCode });
          store.dispatch("translation/fetchData");
          store.dispatch("config/fetchData", { refetch: true });

          f7.panel.get("#leftPanel").toggle();
        },
      },
    };

    const getEventList = async () => {
      let ret = await get("/event/list", { Type: "recent", page: 1, size: 3, SortValue: "E.EventDate", SortType: "DESC", LanguageCode: $t.getLanguage() });

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          eventList.value.push(item);
        }
      }
    };

    const cartPage = () => {
      window.location.href = "/shop/cart/";
    };

    const getPromotionsDiscount = async () => {
      let ret = await get("/promotion/list", { page: 1, size: 1, LanguageCode: $t.getLanguage() });
      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          promotionsList.value.push(item);
        }
      }
    };

    onMounted(() => {
      getEventList();
      getPromotionsDiscount();
    });

    return {
      NotificationLatest,
      isSplash,
      currentLanguageCode,
      languageList,
      smartSelectParams,
      eventList,
      logout,
      promotionsList,
      cartPage,
      store,
    };
  },
};
</script>
