import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    name: 'scanPage',
    path: '/scan/',
    meta: {
      navigation: {
        isShown: true,
        title: 'Scan',
        activeLink: 'scanPage',
        tabbarLink: 'scanPage'
      },
      isSecured: false,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "scan" */ /* webpackMode: "lazy" */ '@/pages/scan/ScanPage.vue')
  }
]

export default routes
