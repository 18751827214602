import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    name: 'profilePage',
    path: '/profile/',
    meta: {
      navigation: {
        isShown: true,
        title: 'Profile',
        activeLink: 'profilePage',
        tabbarLink: 'profilePage'
      },
      isSecured: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "profile" */ /* webpackMode: "lazy" */ '@/pages/profile/ProfilePage.vue'),
    routes: [
      {
        name: 'profilePostPage',
        path: 'post/',
        meta: {
          navigation: {
            isShown: false,
            title: 'Profile Post',
            activeLink: 'profilePostPage',
            tabbarLink: 'profilePage'
          },
          isSecured: false,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "profile-post" */ /* webpackMode: "lazy" */ '@/pages/profile/ProfilePostPage.vue')
      },
      {
        name: 'profileOrderPage',
        path: 'order/',
        meta: {
          navigation: {
            isShown: false,
            title: 'Profile Order',
            activeLink: 'profileOrderPage',
            tabbarLink: 'profilePage'
          },
          isSecured: false,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "profile-order" */ /* webpackMode: "lazy" */ '@/pages/profile/ProfileOrderPage.vue')
      },
      {
        name: 'profileSubOrderPage',
        path: 'order/sub/',
        meta: {
          navigation: {
            isShown: false,
            title: 'Profile Sub Order',
            activeLink: 'profileOrderPage',
            tabbarLink: 'profilePage'
          },
          isSecured: false,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "profile-sub-order" */ /* webpackMode: "lazy" */ '@/pages/profile/ProfileSubOrderPage.vue')
      },
      {
        name: 'profileCouponPage',
        path: 'coupon/',
        meta: {
          navigation: {
            isShown: false,
            title: 'Profile Coupon',
            activeLink: 'profileCouponPage',
            tabbarLink: 'profilePage'
          },
          isSecured: false,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "profile-coupon" */ /* webpackMode: "lazy" */ '@/pages/profile/ProfileCouponPage.vue')
      },
      {
        name: 'profileAddressPage',
        path: 'address/',
        meta: {
          navigation: {
            isShown: false,
            title: 'Profile Address',
            activeLink: 'profileAddressPage',
            tabbarLink: 'profilePage'
          },
          isSecured: false,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "profileaddress" */ /* webpackMode: "lazy" */ '@/pages/profile/ProfileAddressPage.vue')
      },
      {
        name: 'profileUserGuidePage',
        path: 'userguide/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Profile User Guide',
            activeLink: 'profileUserGuidePage',
            tabbarLink: 'userGuidePage'
          },
          isSecured: false,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "profile-user-guide" */ /* webpackMode: "lazy" */ '@/pages/profile/UserGuidePage.vue')
      },
      {
        name: 'profileSettingsPage',
        path: 'settings/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Profile Settings',
            activeLink: 'profileSettingsPage',
            tabbarLink: 'profilePage'
          },
          isSecured: true,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "profile-settings" */ /* webpackMode: "lazy" */ '@/pages/profile/SettingsPage.vue')
      },
      {
        name: 'profileSettingsAccountPage',
        path: 'settings/account/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Profile Settings',
            activeLink: 'profileSettingsAccountPage',
            tabbarLink: 'profilePage'
          },
          isSecured: true,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "profile-setting-account" */ /* webpackMode: "lazy" */ '@/pages/profile/SettingsAccountPage.vue')
      },
      {
        name: 'profileSettingsMergePage',
        path: 'settings/merge/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Profile Settings',
            activeLink: 'profileSettingsMergePage',
            tabbarLink: 'profilePage'
          },
          isSecured: true,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "profile-setting-merge" */ /* webpackMode: "lazy" */ '@/pages/profile/SettingsMergePage.vue')
      },
      {
        name: 'profileSettingsPasswordPage',
        path: 'settings/password/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Profile Settings',
            activeLink: 'profileSettingsPasswordPage',
            tabbarLink: 'profilePage'
          },
          isSecured: true,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "profile-setting-password" */ /* webpackMode: "lazy" */ '@/pages/profile/SettingsPasswordPage.vue')
      },
      {
        name: 'profileMembershipPage',
        path: 'membership/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Profile Membership',
            activeLink: 'profileMembershipPage',
            tabbarLink: 'profilePage'
          },
          isSecured: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "profile-membership" */ /* webpackMode: "lazy" */ '@/pages/profile/MembershipPage.vue')
      },
      {
        name: 'profileSettingsShippingPage',
        path: 'settings/shipping/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Profile Shipping',
            activeLink: 'profileSettingsShippingPage',
            tabbarLink: 'profilePage'
          },
          isSecured: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "profile-setting-shipping" */ /* webpackMode: "lazy" */ '@/pages/profile/SettingsShippingPage.vue')
      },
      {
        name: 'profileSettingsPaymentPage',
        path: 'settings/payment/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Profile Payment',
            activeLink: 'profileSettingsPaymentPage',
            tabbarLink: 'profilePage'
          },
          isSecured: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "profile-setting-payment" */ /* webpackMode: "lazy" */ '@/pages/profile/SettingsPaymentPage.vue')
      },
      {
        name: 'profileWinePreferencePage',
        path: 'settings/wine/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Wine Preference',
            activeLink: 'profileWinePreferencePage',
            tabbarLink: 'profilePage'
          },
          isSecured: true,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "profile-wine-preference" */ /* webpackMode: "lazy" */ '@/pages/profile/SettingsWinePreferencePage.vue')
      },
      {
        name: 'profileUserListPage',
        path: 'userlist/:mode',
        meta: {
          navigation: {
            isShown: false,
            title: 'User List',
            activeLink: 'profileUserListPage',
            tabbarLink: 'profilePage'
          },
          isSecured: true,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "profile-user" */ /* webpackMode: "lazy" */ '@/pages/profile/UserListPage.vue')
      },
      {
        name: 'profileReferralMainPage',
        path: 'referral/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Referrals',
            activeLink: 'profileReferralMainPage',
            tabbarLink: 'profilePage'
          },
          isSecured: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "profile-referral-main" */ /* webpackMode: "lazy" */ '@/pages/profile/ReferralMainPage.vue')
      },
      {
        name: 'profileReferralListPage',
        path: 'referrallist/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Referrals',
            activeLink: 'profileReferralListPage',
            tabbarLink: 'profilePage'
          },
          isSecured: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "profile-referral-list" */ /* webpackMode: "lazy" */ '@/pages/profile/ReferralListPage.vue')
      },
      {
        name: 'profileEligibleOrderListPage',
        path: 'eligilbeorderlist/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Eligible Order',
            activeLink: 'profileEligibleOrderListPage',
            tabbarLink: 'profilePage'
          },
          isSecured: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "profile-referral-order-list" */ /* webpackMode: "lazy" */ '@/pages/profile/EligibleOrderListPage.vue')
      }
    ]
  }
]

export default routes
