const data = {
  activeLink: '',
  activeLinkParent: '',
  tabbarLink: '',
  activeRoute: {},
  navigations: [],
  isSplash: true,
  isFetching: false,
  f7router: {},
  pageViewTracker: null,
  OpenId: null,
  filter: {}
}

export const state = {
  data
}

export const getters = {
  getData: (state) => {
    return state.data
  },
  getActiveLink: (state) => {
    return state.data.activeLink
  },
  getActiveLinkParent: (state) => {
    return state.data.activeLinkParent
  },
  getTabbarLink: (state) => {
    return state.data.tabbarLink
  },
  getActiveRoute: (state) => {
    return state.data.activeRoute
  },
  getNavigations: (state) => {
    return state.data.navigations
  },
  getSplashStatus: (state) => {
    return state.data.isSplash
  },
  getFilterData: (state) => {
    return state.data.filter
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  },
  RESET_DATA(state) {
    state.data = { ...data }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  }
}

export const page = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
