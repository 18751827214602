import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    name: 'surveyPage',
    path: '/survey/',
    meta: {
      navigation: {
        isShown: true,
        title: 'survey',
        activeLink: 'surveyPage',
        tabbarLink: 'surveyPage'
      },
      isSecured: false,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "survey" */ /* webpackMode: "lazy" */ '@/pages/survey/SurveyListPage.vue')
  },
  {
    name: 'viewSurvey',
    path: '/survey/view/',
    meta: {
      navigation: {
        isShown: true,
        title: 'survey',
        activeLink: 'surveyViewPage',
        tabbarLink: 'surveyPage'
      },
      isSecured: false,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "survey-view" */ /* webpackMode: "lazy" */ '@/pages/survey/SurveyViewPage.vue')
  }
]

export default routes
