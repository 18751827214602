import { extendedConfigs } from '@/utils/configs'
import { io } from 'socket.io-client'
import { useStore } from '@/store'

/* MAIN INSTANCE HERE */
let socketReceiveCallback = {}
let main = io(extendedConfigs.chatServer, { reconnection: true, autoConnect: true })
main.open()

main.on('connect', () => {
  //console.log('is socket connected', main.connected)
})

main.on('connect_error', (error) => {
  //console.log('connection failed')
})

main.on('disconnect', () => {
  //console.log('socket disconnected')
})

main.on('message', (data) => {
  //console.log('message', data);
  if (socketReceiveCallback['RECEIVED_MSG']) {
    socketReceiveCallback['RECEIVED_MSG'](data)
  }
})

main.on('notification', (data) => {
  let store = useStore()
  //console.log('notification', data);

  if (data.Mode && data.Mode == 'BADGE') {
    store.dispatch('chat/setData', { Unread: data.Unread })
  }

  if (data.Mode && data.Mode == 'NOTIFICATION') {
    store.dispatch('chat/setData', { NotificationCount: data.Total, NotificationLatest: data.Latest })
  }
})

//global function here, like join, leave, etc.
let socket = {
  getId() {
    if (!main.connected) return null
    return main.id
  },
  unassignSocketCallback(type) {
    delete socketReceiveCallback[type]
  },
  assignSocketCallback(type, cb) {
    socketReceiveCallback[type] = cb
  },
  log(desc) {
    //console.log('[SOCKET]', desc)
  },
  leave(room) {
    socket.log('Leaving room ' + room)
    main.emit('leave', room)
  },
  join(room) {
    //console.log('is socket connected', main.connected)
    if (!main.connected) {
      setTimeout(() => {
        socket.join(room)
      }, 2000) //retry to join after 2 seconds
      return
    }
    socket.log('Joining room ' + room)
    main.emit('join', room)
  }
}

const install = (app) => {
  app.config.globalProperties.$socket = socket
}

export { install as default, socket }
