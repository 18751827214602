import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    name: 'qrscanPage',
    path: '/qrscan',
    meta: {
      navigation: {
        isShown: true,
        title: 'qrscan',
        activeLink: 'qrscanPage',
        tabbarLink: 'qrscanPage'
      },
      isSecured: false,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "qrscan" */ /* webpackMode: "lazy" */ '@/pages/qrscan/QRScanPage.vue')
  }
]

export default routes
