import { checkMeta, checkIsAlreadyLogin } from '@/routes/@common.js'

import { useStore } from '@/store'
const store = useStore()

// REGISTER AUTH MIDDLEWARE
const checkRegistration = ({ to, from, resolve, reject, router }) => {
  const registerInfo = store.getters['register/getData']

  if (registerInfo?.IsOTPGenerated === 1 && to?.route?.name === 'registerMobileAuthenticationPage') {
    reject()
    if (registerInfo.OpenId != '' && registerInfo.IsMergeAccount === 1) {
      return router.navigate('/register/mobilemerge/')
    } else {
      return router.navigate('/register/mobileverification/')
    }
  }

  if (registerInfo?.IsOTPVerified === 1 && to?.route?.name === 'registerMobileVerificationPage') {
    reject()
    return router.navigate('/register/primary/')
  }

  if (registerInfo?.IsUserRegistered === 1 && to?.route?.name !== 'registerPage' && to?.route?.name !== 'registerSuccessPage') {
    reject()
    return router.navigate('/register/success/')
  }

  if (registerInfo?.IsUserRegistered !== 1 && to?.route?.name === 'registerSuccessPage') {
    reject()
    return router.navigate('/login/')
  }

  resolve()
}

const checkIfTermsAgreed = ({ to, from, resolve, reject, router }) => {
  const registerInfo = store.getters['register/getData']

  // if (registerInfo?.IsTermsAgreed !== 1 && registerInfo.IsPrivacyAgreed !== 1 && registerInfo.IsLegalAge !== 1 && registerInfo.IsPrivacyAgreed !== 1 && registerInfo.IsOrderandShipping !== 1 && registerInfo.IsReturn !== 1) {
  //   reject()
  //   return router.navigate('/register/')
  // }
  if (registerInfo.IsLegalAge !== 1 && registerInfo.IsPolicy !== 1) {
    reject()
    return router.navigate('/register/')
  }

  resolve()
}

const checkRegistrationMobileVerified = ({ to, from, resolve, reject, router }) => {
  const registerInfo = store.getters['register/getData']

  if (registerInfo?.IsOTPVerified !== 1) {
    reject()
    return router.navigate('/register/')
  }

  resolve()
}

// FORGOT PASSWORD AUTH MIDDLEWARE
const checkForgotPassword = ({ to, from, resolve, reject, router }) => {
  const forgotPasswordInfo = store.getters['forgotpassword/getData']

  if (forgotPasswordInfo?.IsOTPGenerated === 1 && to?.route?.name === 'forgotPasswordMobileAuthenticationPage') {
    reject()
    return router.navigate('/forgotpassword/mobileverification/')
  }

  if (forgotPasswordInfo?.IsOTPVerified === 1 && to?.route?.name === 'forgotPasswordMobileVerificationPage') {
    reject()
    return router.navigate('/forgotpassword/primary/')
  }

  if (forgotPasswordInfo?.IsPasswordUpdated === 1 && to?.route?.name !== 'forgotPasswordSuccessPage') {
    reject()
    return router.navigate('/forgotpassword/success/')
  }

  if (forgotPasswordInfo?.IsPasswordUpdated !== 1 && to?.route?.name === 'forgotPasswordSuccessPage') {
    reject()
    return router.navigate('/login/')
  }

  resolve()
}

const checkForgotPasswordMobileVerified = ({ to, from, resolve, reject, router }) => {
  const forgotPasswordInfo = store.getters['forgotpassword/getData']

  if (forgotPasswordInfo?.IsOTPVerified !== 1) {
    reject()
    return router.navigate('/forgotpassword/')
  }

  resolve()
}

const routes = [
  {
    name: 'loginPage',
    path: '/login/',
    meta: {
      navigation: {
        isShown: false
      },
      isSecured: false,
      hideLeftPanel: true,
      hideRightPanel: true,
      hideMainNavigation: true,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta, checkIsAlreadyLogin],
    asyncComponent: () => import('@/pages/auth/LoginPage.vue')
  },
  {
    name: 'wechatAuth',
    path: '/auth/wechat',
    meta: {
      navigation: {
        isShown: false
      },
      isSecured: false,
      hideLeftPanel: true,
      hideRightPanel: true,
      hideMainNavigation: true,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta, checkIsAlreadyLogin],
    asyncComponent: () => import('@/pages/wechat/WechatAuthPage.vue')
  },
  {
    name: 'wechatRegister',
    path: '/auth/wechat/register',
    meta: {
      navigation: {
        isShown: false
      },
      isRegister: true,
      isSecured: false,
      hideLeftPanel: true,
      hideRightPanel: true,
      hideMainNavigation: true,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import('@/pages/wechat/WechatRegister.vue')
  },
  {
    name: 'wechatPayment',
    path: '/auth/wechat/payment',
    meta: {
      navigation: {
        isShown: false
      },
      isSecured: true
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import('@/pages/wechat/WechatPayment.vue')
  },
  /*  {
        name: 'redirectAuth',
        path: '/auth/redirect',
        meta: {
          navigation: {
            isShown: false
          },
          isSecured: false,
          hideLeftPanel: true,
          hideRightPanel: true,
          hideMainNavigation: true,
          hideMainToolbar: true,
          hasRoles: []
        },
        asyncComponent: () => import('@/pages/auth/Redirect.vue')
  },*/
  {
    name: 'registerPage',
    path: '/register/',
    meta: {
      navigation: {
        isShown: false
      },
      isSecured: false,
      hideLeftPanel: true,
      hideRightPanel: true,
      hideMainNavigation: true,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta, checkIsAlreadyLogin, checkRegistration],
    asyncComponent: () => import('@/pages/auth/RegisterPage.vue'),
    routes: [
      {
        name: 'registerMobileAuthenticationPage',
        path: 'mobileauthentication/',
        meta: {
          navigation: {
            isShown: false
          },
          isSecured: false,
          hideLeftPanel: true,
          hideRightPanel: true,
          hideMainNavigation: true,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta, checkIsAlreadyLogin, checkRegistration, checkIfTermsAgreed],
        asyncComponent: () => import('@/pages/auth/RegisterMobileAuthenticationPage.vue')
      },
      {
        name: 'registerMobileVerificationPage',
        path: 'mobileverification/',
        meta: {
          navigation: {
            isShown: false
          },
          isSecured: false,
          hideLeftPanel: true,
          hideRightPanel: true,
          hideMainNavigation: true,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta, checkIsAlreadyLogin, checkRegistration, checkIfTermsAgreed],
        asyncComponent: () => import('@/pages/auth/RegisterMobileVerificationPage.vue')
      },
      {
        name: 'RegisterMobileMergePage',
        path: 'mobilemerge/',
        meta: {
          navigation: {
            isShown: false
          },
          isSecured: false,
          hideLeftPanel: true,
          hideRightPanel: true,
          hideMainNavigation: true,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta, checkIsAlreadyLogin, checkRegistration, checkIfTermsAgreed],
        asyncComponent: () => import('@/pages/auth/RegisterMobileMergePage.vue')
      },
      {
        name: 'registerPrimaryPage',
        path: 'primary/',
        meta: {
          navigation: {
            isShown: false
          },
          isSecured: false,
          hideLeftPanel: true,
          hideRightPanel: true,
          hideMainNavigation: true,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta, checkIsAlreadyLogin, checkRegistration, checkIfTermsAgreed, checkRegistrationMobileVerified],
        asyncComponent: () => import('@/pages/auth/RegisterPrimaryPage.vue')
      },
      {
        name: 'registerSuccessPage',
        path: 'success/',
        meta: {
          navigation: {
            isShown: false
          },
          isSecured: false,
          hideLeftPanel: true,
          hideRightPanel: true,
          hideMainNavigation: true,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta, checkIsAlreadyLogin, checkRegistration, checkIfTermsAgreed, checkRegistrationMobileVerified],
        asyncComponent: () => import('@/pages/auth/RegisterSuccessPage.vue')
      },
      {
        name: 'registerComplete',
        path: 'complete/',
        meta: {
          navigation: {
            isShown: false
          },
          isComplete: true,
          isSecured: false,
          hideLeftPanel: true,
          hideRightPanel: true,
          hideMainNavigation: true,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/auth/RegisterCompletePage.vue')
      }
    ]
  },
  {
    name: 'forgotPasswordMobileAuthenticationPage',
    path: '/forgotpassword/',
    meta: {
      navigation: {
        isShown: false
      },
      isSecured: false,
      hideLeftPanel: true,
      hideRightPanel: true,
      hideMainNavigation: true,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta, checkIsAlreadyLogin, checkForgotPassword],
    asyncComponent: () => import('@/pages/auth/ForgotPasswordMobileAuthenticationPage.vue'),
    routes: [
      {
        name: 'forgotPasswordMobileVerificationPage',
        path: 'mobileverification/',
        meta: {
          navigation: {
            isShown: false
          },
          isSecured: false,
          hideLeftPanel: true,
          hideRightPanel: true,
          hideMainNavigation: true,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta, checkIsAlreadyLogin, checkForgotPassword],
        asyncComponent: () => import('@/pages/auth/ForgotPasswordMobileVerificationPage.vue')
      },
      {
        name: 'forgotPasswordPrimaryPage',
        path: 'primary/',
        meta: {
          navigation: {
            isShown: false
          },
          isSecured: false,
          hideLeftPanel: true,
          hideRightPanel: true,
          hideMainNavigation: true,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta, checkIsAlreadyLogin, checkForgotPassword, checkForgotPasswordMobileVerified],
        asyncComponent: () => import('@/pages/auth/ForgotPasswordPrimaryPage.vue')
      },
      {
        name: 'forgotPasswordSuccessPage',
        path: 'success/',
        meta: {
          navigation: {
            isShown: false
          },
          isSecured: false,
          hideLeftPanel: true,
          hideRightPanel: true,
          hideMainNavigation: true,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta, checkIsAlreadyLogin, checkForgotPassword, checkForgotPasswordMobileVerified],
        asyncComponent: () => import('@/pages/auth/ForgotPasswordSuccessPage.vue')
      }
    ]
  }
]

export default routes
