import { checkMeta } from '@/routes/@common.js'

// Import Routes
import auth from '@/routes/auth.js'
import home from '@/routes/home.js'
import shop from '@/routes/shop.js'
import order from '@/routes/order.js'
import scan from '@/routes/scan.js'
import cellar from '@/routes/cellar.js'
import chat from '@/routes/chat.js'
import referral from '@/routes/referral.js'
import profile from '@/routes/profile.js'
import influencer from '@/routes/influencer.js'
import post from '@/routes/post.js'

import notifications from '@/routes/notifications.js'
import events from '@/routes/events.js'
import discounts from '@/routes/discounts.js'
import address from '@/routes/address.js'
import wechat from '@/routes/wechat.js'
import contact from '@/routes/contact.js'
import splash from '@/routes/splash.js'
import about from '@/routes/about.js'
import survey from '@/routes/survey.js'
import qrscan from '@/routes/qrscan.js'
import recommendation from '@/routes/recommendation.js'
import tips from '@/routes/tips.js'
import redirect from '@/routes/redirect.js'


import { configs, extendedConfigs } from '@/utils/configs'

let route = '/splash/'

if (extendedConfigs?.skipSplash) {
  route = extendedConfigs?.defaultSkipSplashRoute
}


const routes = [
  {
    name: '',
    path: '/',
    redirect: ({ to, resolve, reject }) => {
      if(extendedConfigs?.isRedirect){
        setTimeout(() => {
          return window.location.replace(extendedConfigs?.isRedirect)
        }, 800);
      }else{
        return resolve(`${route}`)
      }
    }
  },
  {
    name: '',
    path: '/home',
    redirect: ({ to, resolve, reject }) => {
      return resolve('/home/explorepjf/')
    }
  },
  ...auth,
  ...home,
  ...shop,
  ...order,
  ...scan,
  ...cellar,
  ...chat,
  ...referral,
  ...profile,
  ...influencer,
  ...post,
  ...notifications,
  ...events,
  ...discounts,
  ...address,
  ...wechat,
  ...contact,
  ...about,
  ...splash,
  ...survey,
  ...qrscan,
  ...recommendation,
  ...tips,
  ...redirect,
  {
    name: '',
    path: '/shop',
    redirect: ({ to, resolve, reject }) => {
      return resolve('/shop/')
    }
  },
  {
    path: '(.*)',
    meta: {
      activeLink: '',
      isSecured: false,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "not-found" */ /* webpackMode: "lazy" */ '@/pages/NotFoundPage.vue')
  }
]

export { routes as default }
