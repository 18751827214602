import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    name: 'homePage',
    path: '/home',
    meta: {
      navigation: {
        isShown: true,
        title: 'Home',
        activeLink: 'homePage',
        tabbarLink: 'homePage'
      },
      isSecured: false,
      hasRoles: []
    },
    redirect: '/home/explorepjf/',
    routes: [
      {
        name: 'explorePJFPage',
        path: '/explorepjf/',
        meta: {
          navigation: {
            isShown: false,
            title: 'Explore PJF',
            activeLink: 'explorePJFPage',
            tabbarLink: 'homePage'
          },
          isSecured: false,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "explorepjf" */ /* webpackMode: "lazy" */ '@/pages/home/ExplorePJFPage.vue')
      },
      {
        name: 'explorePage',
        path: 'explore/',
        meta: {
          navigation: {
            isShown: false,
            title: 'Explore',
            activeLink: 'explorePage',
            tabbarLink: 'homePage'
          },
          isSecured: false,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "explore" */ /* webpackMode: "lazy" */ '@/pages/home/ExplorePage.vue')
      },
      {
        name: 'postViewPage',
        path: 'post/view/:key',
        meta: {
          navigation: {
            isShown: false,
            title: 'Post View',
            activeLink: 'postViewPage',
            tabbarLink: 'homePage'
          },
          isSecured: false,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "post-view" */ /* webpackMode: "lazy" */ '@/pages/home/PostViewPage.vue')
      },
      {
        name: 'videoPage',
        path: 'video/',
        meta: {
          navigation: {
            isShown: false,
            title: 'Video',
            activeLink: 'videoPage',
            tabbarLink: 'homePage'
          },
          isSecured: false,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "video" */ /* webpackMode: "lazy" */ '@/pages/home/VideoPage.vue')
      },
      {
        name: 'videoViewPage',
        path: 'video/view/:key',
        meta: {
          navigation: {
            isShown: false,
            title: 'Video View',
            activeLink: 'videoViewPage',
            tabbarLink: 'homePage'
          },
          isSecured: false,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "video-view" */ /* webpackMode: "lazy" */ '@/pages/home/VideoViewPage.vue')
      },
      {
        name: 'articlePage',
        path: 'article/',
        meta: {
          navigation: {
            isShown: false,
            title: 'article',
            activeLink: 'articlePage',
            tabbarLink: 'homePage'
          },
          isSecured: false,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "article" */ /* webpackMode: "lazy" */ '@/pages/home/ArticlePage.vue')
      },
      {
        name: 'articleViewPage',
        path: 'article/view/:key',
        meta: {
          navigation: {
            isShown: false,
            title: 'Article View',
            activeLink: 'articleViewPage',
            tabbarLink: 'homePage'
          },
          isSecured: false,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "article-view" */ /* webpackMode: "lazy" */ '@/pages/home/ArticleViewPage.vue')
      },
      {
        name: 'forYouPage',
        path: 'foryou/',
        meta: {
          navigation: {
            isShown: false,
            title: 'For You',
            activeLink: 'forYouPage',
            tabbarLink: 'homePage'
          },
          isSecured: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "foryou" */ /* webpackMode: "lazy" */ '@/pages/home/ForYouPage.vue')
      },
      {
        name: 'weeklyRecommendation',
        path: 'weekly/',
        meta: {
          navigation: {
            isShown: false,
            title: 'Weekly Recommend',
            activeLink: 'weeklyRecommend',
            tabbarLink: 'homePage'
          },
          isSecured: false,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "weekly" */ /* webpackMode: "lazy" */ '@/pages/home/WeeklyRecommendationPage.vue')
      }
    ]
  }
]

export default routes
