import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    name: 'cellarPage',
    path: '/cellar/',
    meta: {
      navigation: {
        isShown: true,
        title: 'Cellar',
        activeLink: 'cellarPage',
        tabbarLink: 'cellarPage'
      },
      isSecured: true,
      hasRoles: []
    },
    redirect: '/cellar/list/',
    routes: [
      {
        name: 'cellarListPage',
        path: 'list/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Cellar List',
            activeLink: 'cellarListPage',
            tabbarLink: 'cellarPage'
          },
          isSecured: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "cellar-list" */ /* webpackMode: "lazy" */ '@/pages/cellar/CellarListPage.vue')
      }
    ]
  }
]

export default routes
