import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    name: 'orderCheckoutPage',
    path: '/order/checkout',
    meta: {
      navigation: {
        isShown: true,
        title: 'Order Checkout',
        activeLink: 'orderCheckoutPage',
        tabbarLink: 'orderCheckoutPage'
      },
      isSecured: true,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "order-checkout" */ /* webpackMode: "lazy" */ '@/pages/order/OrderCheckoutPage.vue')
  },
  {
    name: 'orderViewPage',
    path: '/order/view/:code',
    meta: {
      navigation: {
        isShown: true,
        title: 'Order View',
        activeLink: 'orderViewPage',
        tabbarLink: 'profilePage'
      },
      isSecured: true,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "order-view" */ /* webpackMode: "lazy" */ '@/pages/order/OrderViewPage.vue')
  },
  {
    name: 'orderReviewPage',
    path: '/order/review/:order/:product',
    meta: {
      navigation: {
        isShown: true,
        title: 'Order Review',
        activeLink: 'orderReviewPage',
        tabbarLink: 'profilePage'
      },
      isSecured: true,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "order-review" */ /* webpackMode: "lazy" */ '@/pages/order/OrderReviewPage.vue')
  },

  {
    name: 'testPayment',
    path: '/order/test',
    meta: {
      navigation: {
        isShown: true,
        title: 'Order Test',
        activeLink: 'orderViewPage',
        tabbarLink: 'profilePage'
      },
      isSecured: false,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "order-view" */ /* webpackMode: "lazy" */ '@/pages/order/OrderTestPage.vue')
  }
]

export default routes
