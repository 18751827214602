let extended = {
  chatServer: 'https://pjfwines.com/signal',
  chatEnabled: true,
  skipSplash: false,
  couponEnabled: true,
  defaultSkipSplashRoute: '/shop',
  showScan: true,
  icp: {
    link: 'https://beian.miit.gov.cn/#/Integrated/recordQuery'
  },
  contact: {
    merchantEmail: 'merchant@pjfwines.com',
    infoEmail: 'info@pjfwines.com'
  },
  smsEnabled: true,
  popupBannerTimer: 5,
  popupBannerShow: true,
  popupBannerMiniShow: false,
  hideProductPrice: false,
  videoSizeLimit: 100,
  canAddToCart: true,
  miniProgram: true,
  pagesToHideToolBar: [
    'orderViewPage',
    'cartPage',
    'chat',
    'splash',
    'registerPage',
    'registerMobileAuthenticationPage',
    'registerMobileVerificationPage',
    'registerPrimaryPage',
    'registerSuccessPage',
    'RegisterMobileMergePage',
    'registerComplete'
  ],
  pageToHidePopupBanner: ['registerPage', 'loginPage', 'splashPage'],
  pageToHidePopupMiniBanner: ['registerPage', 'loginPage', 'splashPage'],
  pageToShowRegisterBanner: ['explorePJFPage', 'postViewPage', 'videoViewPage', 'videoPage', 'weeklyRecommendation'],
  isElastic: true,
  oss: {
    enabled: false,
    endpoint: 'http://pjfassets.cocoho.com'
  },
  isRedirect: null,
  exlucdeCategoriesforBundle: ['BUNDLE_SALE', 'GROUP_BUY', 'FLASH_SALE'],
  isSignupBtn: false,
  isTaxInclusive: false,
  isDummyLikeEnabled: true
}

export default extended
