import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    name: 'shopPage',
    path: '/shop/',
    meta: {
      navigation: {
        isShown: true,
        title: 'Shop',
        activeLink: 'shopPage',
        tabbarLink: 'shopPage'
      },
      isSecured: false,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "shop-page" */ /* webpackMode: "lazy" */ '@/pages/shop/ShopPage.vue'),
    routes: [
      {
        name: 'cartPage',
        path: 'cart/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Cart',
            activeLink: 'cartPage',
            tabbarLink: 'shopPage'
          },
          isCart: true,
          isSecured: true,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "shop-cart-page" */ /* webpackMode: "lazy" */ '@/pages/shop/CartPage.vue')
      },
      {
        name: 'categoryListPage',
        path: 'category/list/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Category List',
            activeLink: 'categoryListPage',
            tabbarLink: 'shopPage'
          },
          isSecured: false,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "shop-category-list" */ /* webpackMode: "lazy" */ '@/pages/shop/CategoryListPage.vue')
      },
      {
        name: 'merchantListPage',
        path: 'merchant/list/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Merchant List',
            activeLink: 'merchantListPage',
            tabbarLink: 'shopPage'
          },
          isSecured: false,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "shop-merchant-list" */ /* webpackMode: "lazy" */ '@/pages/shop/MerchantListPage.vue')
      },
      {
        name: 'merchantViewPage',
        path: 'merchant/view/:code',
        meta: {
          navigation: {
            isShown: true,
            title: 'Merchant View',
            activeLink: 'merchantViewPage',
            tabbarLink: 'shopPage'
          },
          isSecured: false,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "shop-merchant-view" */ /* webpackMode: "lazy" */ '@/pages/shop/MerchantViewPage.vue')
      },
      {
        name: 'productListPage',
        path: 'product/list/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Product List',
            activeLink: 'productListPage',
            tabbarLink: 'shopPage'
          },
          isSecured: false,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "shop-product-list" */ /* webpackMode: "lazy" */ '@/pages/shop/ProductListPage.vue')
      },
      {
        name: 'productViewPage',
        path: 'product/view/:code',
        meta: {
          navigation: {
            isShown: true,
            title: 'Product View',
            activeLink: 'productViewPage',
            tabbarLink: 'shopPage'
          },
          isSecured: false,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "shop-product-view" */ /* webpackMode: "lazy" */ '@/pages/shop/ProductViewPage.vue')
      },
      {
        name: 'productViewPageV2',
        path: 'product/v/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Product View',
            activeLink: 'productViewPageV2',
            tabbarLink: 'shopPage'
          },
          isSecured: false,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "shop-product-view-2" */ /* webpackMode: "lazy" */ '@/pages/shop/ProductViewPageV2.vue')
      },
      //requeted by berry.https://cocoho-ph.atlassian.net/browse/PW-59
      // {
      //   name: 'productViewPage',
      //   path: 'product/view',
      //   meta: {
      //     navigation: {
      //       isShown: true,
      //       title: 'Product View',
      //       activeLink: 'productViewPage',
      //       tabbarLink: 'shopPage'
      //     },
      //     isSecured: false,
      //     hideMainToolbar: true,
      //     hasRoles: []
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/shop/ProductViewPage.vue')
      // },
      {
        name: 'productViewTastingPage',
        path: 'product/view/tasting/:code',
        meta: {
          navigation: {
            isShown: true,
            title: 'Product View Tasting',
            activeLink: 'productViewTastingPage',
            tabbarLink: 'shopPage'
          },
          isSecured: false,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "shop-product-view-tasting" */ /* webpackMode: "lazy" */ '@/pages/shop/ProductViewTastingPage.vue')
      },
      {
        name: 'productViewVineyardPage',
        path: 'product/view/vineyard/:code',
        meta: {
          navigation: {
            isShown: true,
            title: 'Product View Vineyard',
            activeLink: 'productViewVineyardPage',
            tabbarLink: 'shopPage'
          },
          isSecured: false,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "shop-product-view-vineyard" */ /* webpackMode: "lazy" */ '@/pages/shop/ProductViewVineyardPage.vue')
      },
      {
        name: 'countryListPage',
        path: 'country/list/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Country List',
            activeLink: 'countryListPage',
            tabbarLink: 'shopPage'
          },
          isSecured: false,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "shop-country-list" */ /* webpackMode: "lazy" */ '@/pages/shop/CountryListPage.vue')
      },
      {
        name: 'countryViewPage',
        path: 'country/view/:code',
        meta: {
          navigation: {
            isShown: true,
            title: 'Country View',
            activeLink: 'countryViewPage',
            tabbarLink: 'shopPage'
          },
          isSecured: false,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "shop-country-view" */ /* webpackMode: "lazy" */ '@/pages/shop/CountryViewPage.vue')
      },
      {
        name: 'futureWineListPage',
        path: 'wine/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Wine Searcher',
            activeLink: 'futureWineListPage',
            tabbarLink: 'futureWineListPage'
          },
          isSecured: false,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "shop-wine" */ /* webpackMode: "lazy" */ '@/pages/shop/FutureWineListPage.vue')
      },
      {
        name: 'wineryListPage',
        path: 'winery/list/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Winery List',
            activeLink: 'wineryListPage',
            tabbarLink: 'shopPage'
          },
          isSecured: false,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "shop-winery-list" */ /* webpackMode: "lazy" */ '@/pages/shop/WineryListPage.vue')
      },
      {
        name: 'promotionPage',
        path: 'promotion/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Promotion',
            activeLink: 'promotionPage',
            tabbarLink: 'promotionPage'
          },
          isSecured: false,
          hideMainToolbar: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import(/* webpackChunkName: "shop-promotion-page" */ /* webpackMode: "lazy" */ '@/pages/shop/PromotionPage.vue')
      },
    ]
  }
]

export default routes
