const data = {
  IsPolicy: 0,
  IsLegalAge: 0,
  IsNewsletterSubscribed: 0,
  IsTermsAgreed: 0,
  IsPrivacyAgreed: 0,
  IsOrderandShipping: 0,
  IsReturn: 0,
  IsOTPGenerated: 0,
  IsOTPVerified: 0,
  IsUserRegistered: 0,
  IsMobileNumberVerified: 0,
  OTPNextRequest: 0,
  MobileCode: '+86',
  MobileNumber: '',
  VerificationCode: '',
  UserName: '',
  Email: '',
  Password: '',
  ConfirmPassword: '',
  FirstName: '',
  LastName: '',
  BirthDate: '',
  Gender: '',
  Age: '',
  GroupLinkCode: '',
  OpenId: '',
  RegistrationType: '',
  IsMergeAccount: 0,
  IsMergeSuccess: 0
}

export const state = {
  data
}
export const getters = {
  getData: (state) => {
    return state.data
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  },
  RESET_DATA(state) {
    state.data = { ...data }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  resetData: (context) => {
    context.commit('RESET_DATA')
  }
}

export const register = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
