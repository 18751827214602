import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    name: 'eventsPage',
    path: '/events/',
    meta: {
      navigation: {
        isShown: true,
        title: 'events',
        activeLink: 'eventsPage',
        tabbarLink: 'eventsPage'
      },
      isSecured: false,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "events" */ /* webpackMode: "lazy" */ '@/pages/events/EventsPage.vue')
  },
  {
    name: 'eventsViewPage',
    path: '/events/view/:code',
    meta: {
      navigation: {
        isShown: true,
        title: 'events',
        activeLink: 'eventsViewPage',
        tabbarLink: 'eventsPage'
      },
      isSecured: false,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "events-view" */ /* webpackMode: "lazy" */ '@/pages/events/EventsViewPage.vue')
  }
]

export default routes
