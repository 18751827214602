import { checkMeta } from '@/routes/@common.js';
const routes = [
  {
    name: 'about',
    path: '/about/',
    meta: {
      navigation: {
        isShown: false,
        title: 'about',
        activeLink: 'about',
        tabbarLink: 'aboutPage'
      },
      isSecured: false,
      hideMainToolbar: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "about" */ /* webpackMode: "lazy" */ '@/pages/about/AboutUsPage.vue')
  }
]

export default routes
