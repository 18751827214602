import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    name: 'weekylyrecommendationPage',
    path: '/recommendation/',
    meta: {
      hideMainToolbar: false,
      isSecured: false,
      hasRoles: [],
      navigation: {
        isShown: true,
        title: 'Recommendation',
        activeLink: 'weekylyrecommendationPage',
        tabbarLink: 'weekylyrecommendationPage'
      }
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import(/* webpackChunkName: "recommendations" */ /* webpackMode: "lazy" */ '@/pages/recommendation/WeeklyRecommendationPage.vue')
  }
]

export default routes
