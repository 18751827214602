import { post } from '@/utils/axios'

const data = {
  POST: [],
  POSTPRODUCT: [],
  MERCHANT: [],
  INFLUENCER: [],
  EVENT: [],
  PRODUCT: [],
  PROMOTION: [],
  referral: '',
  linkCode: ''
}

export const state = {
  data
}

export const getters = {
  getData: (state) => {
    return state.data
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  },
  RESET_DATA(state) {
    state.data = { ...data }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  increase: async (context, payload) => {
    const viewInfo = context?.state?.data[payload?.type]

    if (viewInfo && viewInfo.indexOf(payload?.key) > -1) {
      return true
    } else {
      // UPDATE Session payloads
      let updatePayload = {}
      viewInfo.push(payload?.key)
      updatePayload[payload.type] = viewInfo
      context.commit('UPDATE_DATA', updatePayload)

      // ADD Payload referral if available
      if (context?.state?.data?.referral) {
        payload.referral = context?.state?.data?.referral
      }

      // ADD Payload linkCode if available
      if (context?.state?.data?.linkCode) {
        payload.linkCode = context?.state?.data?.linkCode
      }

      // POST to server to increase counter
      await post('/view/increase', payload)
    }
  }
}

export const view = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
