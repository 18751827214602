<template>
  <f7-panel id="rightPanel" right reveal>
    <f7-page>
      <f7-navbar>
        <f7-nav-right>
          <f7-link class="close" panel-toggle="#rightPanel">
            <font-awesome-icon :icon="['far', 'times']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
    </f7-page>
  </f7-panel>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
import { logout } from "@/logics/auth.js";

export default defineComponent({
  name: "PanelRightComponent",
  components: {
    
  },
  setup() {
    const store = useStore();
    const userInfo = computed(() => store.getters["user/getData"]);

    return { userInfo, logout };
  },
});
</script>
