<template>
  <!-- Main Framework7 App component where we pass Framework7 params -->
  <f7-app v-bind="f7params">
    <!-- Main view-->
    <PanelLeftComponent v-if="!hideLeftPanel" />
    <f7-view id="main-view" main url="/" :init="false">
      <MainToolbarComponent v-if="!hideMainToolbar" />
      <PopupBannerComponent :show="!hidePopupBanner" />
      <PopupMiniBannerComponent :show="!hidePopupMiniBanner" />
      <!-- <RegisterPopupBannerComponent v-if="showRegisterBanner" /> -->
    </f7-view>

    <PanelRightComponent v-if="!hideRightPanel" />
  </f7-app>
</template>

<script>
import { $HTTP } from '@/utils/axios'
import routes from '@/routes'
import { useStore } from '@/store'

import { defineComponent, onMounted, onErrorCaptured, computed, provide } from 'vue'
import { f7ready } from 'framework7-vue'

import { helpers } from '@/utils/helpers.js'
import { configs } from '@/utils/configs.js'
import { translation } from '@/utils/translation.js'
import { socket } from '@/utils/socket.js' //import only to connect automatically

import PopupBannerComponent from '@/components/PopupBannerComponent.vue'
import PopupMiniBannerComponent from '@/components/PopupMiniBannerComponent.vue'
// import RegisterPopupBannerComponent from '@/components/RegisterPopupBannerComponent.vue'
import MainToolbarComponent from '@/components/navigations/MainToolbarComponent.vue'

import PanelLeftComponent from '@/components/PanelLeftComponent.vue'
import PanelRightComponent from '@/components/PanelRightComponent.vue'
import { saveOpendId } from '@/logics/payment'

export default defineComponent({
  name: 'MainApp',
  components: {
    MainToolbarComponent,
    PanelLeftComponent,
    PanelRightComponent,
    PopupBannerComponent,
    PopupMiniBannerComponent
    // RegisterPopupBannerComponent
  },
  setup() {
    provide('$translation', translation)

    const store = useStore()

    // FETCH CONFIG DATA
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    if (params && params.lang) {
      //static first
      if (['EN', 'CHS'].indexOf(params.lang) > -1) {
        //store lang
        store.dispatch('translation/setLanguage', { languageCode: params.lang })
        store.dispatch('translation/fetchData')
        store.dispatch('config/fetchData', { refetch: true })

        delete params.lang
        var queryString = Object.keys(params)
          .map((key) => key + '=' + params[key])
          .join('&')
        window.location.replace('?' + queryString)
        //history.pushState(null, '', '');
      }
    }

    store.dispatch('config/fetchData', { params: { languageList: 1 } })
    store.dispatch('translation/fetchData')
    store.dispatch('chat/connect')
    store.dispatch('cart/fetchData', { count: true })
    store.dispatch('page/setData', { pageViewTracker: null })

    //store.dispatch('user/connect')

    const f7params = {
      routes,
      view: {
        browserHistory: true,
        browserHistorySeparator: '',
        transition: 'f7-push',
        preloadPreviousPage: false,
        pushState: true,
        stackPages: false,
        // uniqueHistory: true,
        animate: false
      },
      root: '#main-view',
      name: configs.title,
      id: configs.appId || 'app.unique.id',
      language: 'EN',
      theme: 'md',
      dialog: {
        title: configs.title
      },
      notification: {
        title: configs.title,
        closeTimeout: 3000
      }
    }

    const hideLeftPanel = computed(() => store.getters['page/getActiveRoute']?.meta?.hideLeftPanel)
    const hideRightPanel = computed(() => store.getters['page/getActiveRoute']?.meta?.hideRightPanel)
    const hideMainToolbar = computed(() => store.getters['page/getActiveRoute']?.meta?.hideMainToolbar)
    const hidePopupBanner = computed(() => store.getters['page/getActiveRoute']?.meta?.hidePopupBanner)
    const hidePopupMiniBanner = computed(() => store.getters['page/getActiveRoute']?.meta?.hidePopupMiniBanner)
    const showRegisterBanner = computed(() => store.getters['page/getActiveRoute']?.meta?.showRegisterBanner)

    onMounted(async () => {
      if (params && params.mpc) {
        await saveOpendId(store, params.mpc)
      }

      window.addEventListener('beforeunload', () => {
        const { pageViewTracker } = store.getters['page/getData']

        if (!pageViewTracker) return
        $HTTP.post('/page/unload', { pageViewTracker })
      })

      f7ready((f7) => {
        const mainView = f7.views.create('#main-view', {
          url: '/'
        })

        provide('$f7router', mainView.router)

        const leftPanel = f7.panel.get('.panel-left')
        const rightPanel = f7.panel.get('.panel-right')
      })
    })

    onErrorCaptured((error) => {
      helpers.catchError(error, true)
    })

    return { f7params, hideLeftPanel, hideRightPanel, hideMainToolbar, hidePopupBanner, hidePopupMiniBanner, showRegisterBanner }
  }
})
</script>

<style></style>
