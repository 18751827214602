import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { configs } from '@/utils/configs'

import SecureLS from '@/libs/secure-ls'
const secureLocalStorage = new SecureLS({ isLocalStorage: true, isCompression: true, encodingType: 'rc4', encryptionSecret: configs.encryptionSecret })
const secureSessionStorage = new SecureLS({ isLocalStorage: false, isCompression: true, encodingType: 'rc4', encryptionSecret: configs.encryptionSecret })

import { config } from './config'
import { translation } from './translation'
import { page } from './page'
import { view } from './view'
import { share } from './share'
import { user } from './user'
import { cart } from './cart'
import { chat } from './chat'
import { register } from './register'
import { forgotpassword } from './forgotpassword'
import { referral } from './referral'

const persistedLocalStorage = createPersistedState({
  key: configs.tokenSession,
  paths: ['user', 'cart', 'translation'],
  storage: {
    getItem: (key) => secureLocalStorage.get(key),
    setItem: (key, value) => secureLocalStorage.set(key, value),
    removeItem: (key) => secureLocalStorage.remove(key)
  }
})

const persistedSessionStorage = createPersistedState({
  key: configs.tokenSession,
  paths: ['page', 'view', 'share', 'config', 'register', 'forgotpassword', 'chat', 'referral'],
  storage: {
    getItem: (key) => secureSessionStorage.get(key),
    setItem: (key, value) => secureSessionStorage.set(key, value),
    removeItem: (key) => secureSessionStorage.remove(key)
  }
})

export const store = createStore({
  modules: {
    config,
    translation,
    page,
    view,
    share,
    user,
    cart,
    chat,
    register,
    forgotpassword,
    referral
  },
  plugins: [persistedLocalStorage, persistedSessionStorage]
})

export function useStore() {
  return store
}

export default store
